<app-header></app-header>

<section class="title-ch t-pur">
  <div class="container">
      <div class="row">
          <h5>MIS PEDIDOS</h5>
          <div class="colorbar"><span></span></div>
          <form class="d-none d-sm-block">
              <div class="row">
                <div class="col">
                  <label for="since">Desde</label>
                  <input #inputDesde type="date" class="form-control">
                </div>
                <div class="col">
                    <label for="to">Hasta</label>
                    <input #inputHasta type="date" class="form-control">
                </div>
                <button type="submit" class="btn btn-primary" (click)="buscar()">Buscar</button>
              </div>
            </form>
      </div>
  </div>
</section>

<section class="ch-information chart-detail">
  <div class="container">
      <div class="row chart-table d-none d-md-block">
          <table class="table table-striped">
              <thead>
                  <tr>
                      <th scope="col">Código</th>
                      <th scope="col">Forma de pago</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Estado</th>
                      <th scope="col">Total</th>
                      <th scope="col">Detalle</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let venta of ventas">
                      <th scope="row">{{ venta.codigo }}</th>
                      <td *ngIf="venta.pago.tipo == 'EFECTIVO'">Efectivo</td>
                      <td *ngIf="venta.pago.tipo == 'MERCADO_PAGO'">Tarjeta</td>
                      <td>{{ venta.fechaAlta }}</td>

                      <td *ngIf="venta.estado == 'PENDIENTE'">Carrito de compras</td>
                      <td *ngIf="venta.estado == 'ENTREGADO'">Entregada</td>
                      <td *ngIf="venta.estado == 'PENDIENTE_EFECTIVO'">Pago en efectivo pendiente</td>
                      <td *ngIf="venta.estado == 'PENDIENTE_TARJETA'">Pago con tarjeta pendiente</td>
                      <td *ngIf="venta.estado == 'PAGADA'">Pagada</td>
                      <td *ngIf="venta.estado == 'EN_PREPARACION'">En preparación</td>
                      <td *ngIf="venta.estado == 'CANCELADA'">Cancelada</td>

                      <td >{{ venta.monto }}</td>

                      <td class=""><a (click)="elegirVenta(venta)" href="/purchase/detail">Ver detalle</a></td>
                  </tr>
              </tbody>
          </table>
      </div>

      <div class="row purch-det d-block d-md-none">
          <div class="detail-p" *ngFor="let venta of ventas">
              <p>Código: <span>{{ venta.codigo }}</span></p>
              <p *ngIf="venta.pago != null">Forma de pago:
                <span *ngIf="venta.pago.tipo == 'EFECTIVO'">Contado</span>
                <span *ngIf="venta.pago.tipo == 'MERCADO_PAGO'">Tarjeta</span>
              </p>
              <p>Fecha: <span>{{ venta.fechaAlta }}</span></p>
              <p>Estado:
                <span *ngIf="venta.estado == 'PENDIENTE'">Carrito de compras</span>
                <span *ngIf="venta.estado == 'ENTREGADO'">Entregada</span>
                <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO'">Pago en efectivo pendiente</span>
                <span *ngIf="venta.estado == 'PAGADA'">Pagada</span>
                <span *ngIf="venta.estado == 'EN_PREPARACION'">En preparación</span>
              </p>
              <p>Pagado: $
                <span *ngIf="venta.estado == 'PENDIENTE'">-</span>
                <span *ngIf="venta.estado == 'ENTREGADO'">{{ venta.monto }}</span>
                <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado == 'PENDIENTE'">0.00</span>
                <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado != 'PENDIENTE'">{{ venta.monto }}</span>
                <span *ngIf="venta.estado == 'PAGADA'">{{ venta.monto }}</span>
                <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado == 'PENDIENTE'">0.00</span>
                <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado != 'PENDIENTE'">{{ venta.monto }}</span>
              </p>
              <p>Adeudado: $
                <span *ngIf="venta.estado == 'PENDIENTE'">-</span>
                <span *ngIf="venta.estado == 'ENTREGADO'">0.00</span>
                <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado == 'REALIZADO'">0.00</span>
                <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado != 'REALIZADO'">{{ venta.monto }}</span>
                <span *ngIf="venta.estado == 'PAGADA'">0.00</span>
                <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado == 'REALIZADO'">0.00</span>
                <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado != 'REALIZADO'">{{ venta.monto }}</span>
              </p>
              <p>Total: $<span>{{ venta.monto }}</span></p>
              <p><a (click)="elegirVenta(venta)" href="/purchase/detail">Ver detalle</a></p>
              <div class="g-bar"></div>
          </div>
      </div>
  </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
