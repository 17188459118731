import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DepartamentoServicio = /** @class */ (function () {
    function DepartamentoServicio(http) {
        this.http = http;
        this.server = environment.baseUrl + '/api/departamentos';
    }
    DepartamentoServicio.prototype.listarDepartamentos = function () {
        return this.http.get(this.server);
    };
    DepartamentoServicio.ngInjectableDef = i0.defineInjectable({ factory: function DepartamentoServicio_Factory() { return new DepartamentoServicio(i0.inject(i1.HttpClient)); }, token: DepartamentoServicio, providedIn: "root" });
    return DepartamentoServicio;
}());
export { DepartamentoServicio };
