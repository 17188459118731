<app-header></app-header>

<section class="title-ch">
  <div class="container">
    <div class="row">
      <h5>MODIFICAR CONTRASEÑA</h5>
      <div class="colorbar"><span></span></div>
    </div>
  </div>
</section>

<section class="ch-information">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">

        <form [formGroup]="claveForm" (ngSubmit)="modificarClave()">
          <div class="form-group">
            <label for="">Actual contraseña</label>
            <input formControlName="actual" type="password" class="form-control" placeholder="******">
          </div>
          <div class="form-group">
            <label for="">Nueva contraseña</label>
            <input formControlName="nueva" type="password" class="form-control" placeholder="******">
          </div>

          <div class="form-group">
            <label for="">Repetir nueva contraseña</label>
            <input formControlName="nuevaR" type="password" class="form-control" placeholder="******">
          </div>

          <button type="submit" class="btn btn-primary">Modificar contraseña</button>
        </form>
      </div>

    </div>
  </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>