/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-results.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "../../servicios/producto.servicio";
import * as i6 from "../footer/footer.component.ngfactory";
import * as i7 from "../footer/footer.component";
import * as i8 from "./no-results.component";
var styles_NoResultsComponent = [i0.styles];
var RenderType_NoResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoResultsComponent, data: {} });
export { RenderType_NoResultsComponent as RenderType_NoResultsComponent };
export function View_NoResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.Router, i5.ProductoServicio, i4.ActivatedRoute], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container cont-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "rows"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uups! B\u00FAsqueda sin resultados."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "img-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid"], ["src", "/assets/images/no-results-found.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i7.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 10, 0); }, null); }
export function View_NoResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-results", [], null, null, null, View_NoResultsComponent_0, RenderType_NoResultsComponent)), i1.ɵdid(1, 114688, null, 0, i8.NoResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoResultsComponentNgFactory = i1.ɵccf("app-no-results", i8.NoResultsComponent, View_NoResultsComponent_Host_0, {}, {}, []);
export { NoResultsComponentNgFactory as NoResultsComponentNgFactory };
