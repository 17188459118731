import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProductoServicio = /** @class */ (function () {
    function ProductoServicio(http) {
        this.http = http;
        this.server = environment.baseUrl + '/api/producto';
    }
    ProductoServicio.prototype.listarCategorias = function () {
        return this.http.get(this.server + '/categorias');
    };
    ProductoServicio.prototype.listarOfertas = function () {
        return this.http.get(this.server + '/ofertas');
    };
    ProductoServicio.prototype.listarNovedades = function () {
        return this.http.get(this.server + '/novedades');
    };
    ProductoServicio.prototype.listarMasComprados = function () {
        return this.http.get(this.server + '/mas-comprados');
    };
    ProductoServicio.prototype.pageProductosPorCategoria = function (idCategoria, pagina) {
        var p = {};
        p['size'] = 16;
        p['page'] = pagina;
        return this.http.get(this.server + '/categorias/' + idCategoria, { params: p });
    };
    ProductoServicio.prototype.pageProductosPorNombre = function (nombre, pagina) {
        var p = {};
        p['size'] = 16;
        p['page'] = pagina;
        p['nombre'] = nombre;
        return this.http.get(this.server + '/buscar', { params: p });
    };
    ProductoServicio.prototype.buscarPorAmigable = function (amigable) {
        return this.http.get(this.server + '/' + amigable);
    };
    ProductoServicio.prototype.buscarPorNombre = function (nombre) {
        var p = {
            nombre: nombre
        };
        return this.http.get(this.server, { params: p });
    };
    ProductoServicio.prototype.getImagen = function (foto) {
        return this.server + '/abrir?foto=' + foto;
    };
    ProductoServicio.prototype.incrementarVistas = function (productoId) {
        return this.http.post(this.server + '/visitas/' + productoId, null);
    };
    ProductoServicio.ngInjectableDef = i0.defineInjectable({ factory: function ProductoServicio_Factory() { return new ProductoServicio(i0.inject(i1.HttpClient)); }, token: ProductoServicio, providedIn: "root" });
    return ProductoServicio;
}());
export { ProductoServicio };
