<app-header></app-header>

<section class="m-chart d-block d-md-none" *ngIf="carrito.length > 0">
	<div class="container">
		<button type="button" class="btn chart-button" data-toggle="collapse"
			data-target="#collapseChart" aria-expanded="false"
			aria-controls="collapseExample">

			<img class="img-fluid" src="/assets/images/supermarket.svg"
				alt="chart"> <span class="badge badge-light">{{
				carrito.length }}</span>
		</button>
		<div class="collapse" id="collapseChart">
			<div class="card card-body">
				<div class="container">
					<div class="chart-p" *ngFor="let detalle of carrito; index as i">
						<div class="row c-chart">
							<a style="cursor: pointer;"
								(click)="abrirModalEliminar(detalle, i)"><span
								class="lnr lnr-cross"></span></a>
						</div>
						<div class="row c-info-p">
							<div class="col-3 col-lg-3 c-info-img">
								<img class="img-fluid"
									*ngIf="producto?.foto != null && producto?.foto != ''"
									[src]="pathImagen(producto?.foto)"
									alt="{{ producto?.amigable }}"
                  onerror="this.src='/assets/images/Logo-ER(3).png'"> <img class="img-fluid"
									*ngIf="producto?.foto == null || producto?.foto == ''"
									src="/assets/images/Logo-ER(3).png"
									alt="{{ producto?.amigable }}">
							</div>
							<div class="col-5 col-lg-6">
								<h6 style="-webkit-box-orient: vertical;">{{
									detalle.producto?.nombre }}</h6>
								<span>x<span>{{ detalle.cantidad }}</span></span>
							</div>
							<div class="col-4 col-lg-3">
								<p>
									$<span>{{ detalle.monto.toString().substring(0,
										detalle.monto.toString().indexOf('.')) }},<sup>{{
											detalle.monto.toString().substring(detalle.monto.toString().indexOf('.')
											+ 1) }}</sup>
									</span>
								</p>
							</div>
						</div>
						<div class="row p-character">
							<div class="form-row align-items-center">
								<div class="col-10">
									<input type="text" class="form-control"
										placeholder="Color|Tamaño|Medida|Otras"
										[(ngModel)]="detalle.observacion">
								</div>
								<div class="col-2">
									<button type="submit" class="btn"
										(click)="agregarObservacion(detalle)">
										<span class="lnr lnr-pencil"></span>
									</button>
								</div>
							</div>
						</div>
						<div class="g-bar"></div>
					</div>
					<div class="row total" *ngIf="carrito.length > 0">
						<div class="col-4">
							Total: $<span> {{ totalCarrito }}</span>
						</div>
						<div class="col-5">
							<a href="/checkout" *ngIf="logueado != null">Finalizar compra</a>
							<a href="/login" *ngIf="logueado == null">Finalizar compra</a>
						</div>
						<div class="col-3">
							<a style="cursor: pointer;" (click)="abrirModalVaciar()"><span
								class="lnr lnr-trash"></span>Vaciar</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>

<div class="container">
	<div class="row">
		<div class="col-12" [ngClass]="{'col-md-8': carrito.length > 0}">
			<section class="title-index2">
				<div class="container">
					<div class="row">
						<h3>Novedades</h3>
					</div>
				</div>
			</section>
			<section class="i-products-chart">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div id="carouselNovedades"
								class="carousel slide d-none d-md-block" data-ride="carousel">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let producto of novedades[0]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p class="btn-agotado">AGOTADO!</p>
													</ng-template>

												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let producto of novedades[1]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
                                 alt="{{ producto?.amigable }}"
                                 onerror="this.src=/assets/images/Logo-ER(3).png"><img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carouselNovedades"
									role="button" data-slide="prev"> <span
									class="carousel-control-prev-icon" aria-hidden="true"></span> <span
									class="sr-only">Anterior</span>
								</a> <a class="carousel-control-next" href="#carouselNovedades"
									role="button" data-slide="next"> <span
									class="carousel-control-next-icon" aria-hidden="true"></span> <span
									class="sr-only">Siguiente</span>
								</a>
							</div>

							<div id="carouselNovedades2"
								class="carousel slide d-block d-md-none" data-ride="carousel">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of novedadesMobile[0]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of novedadesMobile[1]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of novedadesMobile[2]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of novedadesMobile[3]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carouselNovedades2"
									role="button" data-slide="prev"> <span
									class="carousel-control-prev-icon" aria-hidden="true"></span> <span
									class="sr-only">Anterior</span>
								</a> <a class="carousel-control-next" href="#carouselNovedades2"
									role="button" data-slide="next"> <span
									class="carousel-control-next-icon" aria-hidden="true"></span> <span
									class="sr-only">Siguiente</span>
								</a>
							</div>

						</div>
					</div>
				</div>
			</section>
			<section class="title-index">
				<div class="container">
					<div class="row">
						<h3>Ofertas</h3>
					</div>
				</div>
			</section>
			<section class="i-products-chart">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div id="carouselOfertas"
								class="carousel slide d-none d-md-block" data-ride="carousel">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let oferta of ofertas[0]">
                          <div class="img-index">
                            <img class="img-fluid"
                                 *ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
                                 [src]="pathImagen(oferta.producto?.foto)"
                                 alt="{{ oferta.producto?.amigable }}"
                                 onerror="this.src='/assets/images/Logo-ER(3).png'">
                            <img class="img-fluid"
                                 *ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
                                 src="/assets/images/Logo-ER(3).png"
                                 alt="{{ oferta.producto?.amigable }}">
                          </div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ oferta.producto?.amigable }}"
															class="i-name">{{ oferta.producto?.nombre }}</a>
													</div>
                          <div style="position: relative;">
                            <div style="position: absolute; bottom: -94px; right: 90px; background-color: red; color: white; border-radius: 50%; padding: 5px 10px; font-size: 12px;">
                              ¡Oferta!
                            </div>
                          </div>
													<p>
														$<span>{{ oferta.monto }}</span>
													</p>
                          <div
                            *ngIf="oferta.producto?.disponible ; then productoConStock else productoSinStock">
                          </div>

                          <ng-template #productoConStock>
                            <div class="button-b">
                              <a style="cursor: pointer;"
                                 (click)="agregarCarritoOferta(oferta)" class="i-buy"><span
                                class="lnr lnr-cart"></span>Comprar</a>
                            </div>
                          </ng-template>
                          <ng-template #productoSinStock>
                            <p class="btn-agotado">AGOTADO!</p>
                          </ng-template>

												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let oferta of ofertas[1]">
                          <div class="img-index">
														<img class="img-fluid"
															*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
															[src]="pathImagen(oferta.producto?.foto)"
															alt="{{ oferta.producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ oferta.producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ oferta.producto?.amigable }}"
															class="i-name">{{ oferta.producto?.nombre }}</a>
													</div>
													<p>
														$<span>{{ oferta.monto }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>

													</ng-template>
                          <ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>

												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carouselOfertas"
									role="button" data-slide="prev"> <span
									class="carousel-control-prev-icon" aria-hidden="true"></span> <span
									class="sr-only">Anterior</span>
								</a> <a class="carousel-control-next" href="#carouselOfertas"
									role="button" data-slide="next"> <span
									class="carousel-control-next-icon" aria-hidden="true"></span> <span
									class="sr-only">Siguiente</span>
								</a>
							</div>

							<div id="carouselOfertas2"
								class="carousel slide d-block d-md-none" data-ride="carousel">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let oferta of ofertasMobile[0]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
															[src]="pathImagen(oferta.producto?.foto)"
															alt="{{ oferta.producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ oferta.producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ oferta.producto?.amigable }}"
															class="i-name">{{ oferta.producto?.nombre }}</a>
													</div>
                          <div style="position: relative;">
                            <div style="position: absolute; bottom: -102px; right: 90px; background-color: red; color: white; border-radius: 50%; padding: 5px 10px; font-size: 12px;">
                              ¡Oferta!
                            </div>
                          </div>
													<p>
														$<span>{{ oferta.monto }}</span>
													</p>
													<div
														*ngIf="producto?.disponible; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let oferta of ofertasMobile[1]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
															[src]="pathImagen(oferta.producto?.foto)"
															alt="{{ oferta.producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ oferta.producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ oferta.producto?.amigable }}"
															class="i-name">{{ oferta.producto?.nombre }}</a>
													</div>
													<p>
														$<span>{{ oferta.monto }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let oferta of ofertasMobile[2]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
															[src]="pathImagen(oferta.producto?.foto)"
															alt="{{ oferta.producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ oferta.producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ oferta.producto?.amigable }}"
															class="i-name">{{ oferta.producto?.nombre }}</a>
													</div>
													<p>
														$<span>{{ oferta.monto }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch" *ngFor="let oferta of ofertasMobile[3]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
															[src]="pathImagen(oferta.producto?.foto)"
															alt="{{ oferta.producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ oferta.producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ oferta.producto?.amigable }}"
															class="i-name">{{ oferta.producto?.nombre }}</a>
													</div>
													<p>
														$<span>{{ oferta.monto }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carouselOfertas2"
									role="button" data-slide="prev"> <span
									class="carousel-control-prev-icon" aria-hidden="true"></span> <span
									class="sr-only">Anterior</span>
								</a> <a class="carousel-control-next" href="#carouselOfertas2"
									role="button" data-slide="next"> <span
									class="carousel-control-next-icon" aria-hidden="true"></span> <span
									class="sr-only">Siguiente</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="title-index">
				<div class="container">
					<div class="row">
						<h3>Productos más vendidos</h3>
					</div>
				</div>
			</section>
			<section class="i-products-chart">
				<div class="container">
					<div class="row">
						<div class="col-12">

							<div id="carouselMasVendidos"
								class="carousel slide d-none d-md-block" data-ride="carousel">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of masComprados[0]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
														<div class="button-b">
															<a style="cursor: pointer;"
																(click)="agregarCarrito(producto)" class="i-buy"><span
																class="lnr lnr-cart"></span>Comprar</a>
														</div>
													</ng-template>
													<ng-template #productoSinStock>
														<p class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of masComprados[1]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carouselMasVendidos"
									role="button" data-slide="prev"> <span
									class="carousel-control-prev-icon" aria-hidden="true"></span> <span
									class="sr-only">Anterior</span>
								</a> <a class="carousel-control-next" href="#carouselMasVendidos"
									role="button" data-slide="next"> <span
									class="carousel-control-next-icon" aria-hidden="true"></span> <span
									class="sr-only">Siguiente</span>
								</a>
							</div>

							<div id="carouselMasVendidos2"
								class="carousel slide d-block d-md-none" data-ride="carousel">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of masCompradosMobile[0]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of masCompradosMobile[1]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of masCompradosMobile[2]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="carousel-item">
										<div class="container">
											<div class="row">
												<div class="card-ch"
													*ngFor="let producto of masCompradosMobile[3]">
													<div class="img-index">
														<img class="img-fluid"
															*ngIf="producto?.foto != null && producto?.foto != ''"
															[src]="pathImagen(producto?.foto)"
															alt="{{ producto?.amigable }}"
                              onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
															class="img-fluid"
															*ngIf="producto?.foto == null || producto?.foto == ''"
															src="/assets/images/Logo-ER(3).png"
															alt="{{ producto?.amigable }}">
													</div>
													<div class="box">
														<a style="-webkit-box-orient: vertical;"
															href="/producto/{{ producto?.amigable }}" class="i-name">{{
															producto?.nombre }}</a>
													</div>
													<p *ngIf="logueado != null && logueado.lista == 1">
														$<span>{{ producto.precio1 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 2">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 3">
														$<span>{{ producto.precio3 }}</span>
													</p>
													<p *ngIf="logueado != null && logueado.lista == 4">
														$<span>{{ producto.precio4 }}</span>
													</p>
													<p *ngIf="logueado == null">
														$<span>{{ producto.precio2 }}</span>
													</p>
													<div
														*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
													</div>
													<ng-template #productoConStock>
													<div class="button-b">
														<a style="cursor: pointer;"
															(click)="agregarCarrito(producto)" class="i-buy"><span
															class="lnr lnr-cart"></span>Comprar</a>
													</div>
													</ng-template>
													<ng-template #productoSinStock>
													<p
														class="btn-agotado">AGOTADO!</p>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carouselMasVendidos2"
									role="button" data-slide="prev"> <span
									class="carousel-control-prev-icon" aria-hidden="true"></span> <span
									class="sr-only">Anterior</span>
								</a> <a class="carousel-control-next" href="#carouselMasVendidos2"
									role="button" data-slide="next"> <span
									class="carousel-control-next-icon" aria-hidden="true"></span> <span
									class="sr-only">Siguiente</span>
								</a>
							</div>

						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="d-none d-md-block col-md-4">
			<div class="chart" *ngIf="carrito.length > 0">
				<h6 *ngIf="carrito.length > 0">MI CARRITO</h6>
				<div class="colorbar" *ngIf="carrito.length > 0">
					<span></span>
				</div>
				<div class="scroll" *ngIf="carrito.length > 0">
					<div class="chart-p" *ngFor="let detalle of carrito; index as i">
						<div class="row c-chart">
							<a style="cursor: pointer;"
								(click)="abrirModalEliminar(detalle, i)"><span
								class="lnr lnr-cross"></span></a>
						</div>
						<div class="row">
							<div class="col-3 col-lg-3 img-p-ch">
								<img class="img-fluid"
									*ngIf="producto?.foto != null && producto?.foto != ''"
									[src]="pathImagen(producto?.foto)"
									alt="{{ producto?.amigable }}"
                  onerror="this.src='/assets/images/Logo-ER(3).png'"> <img class="img-fluid"
									*ngIf="producto?.foto == null || producto?.foto == ''"
									src="/assets/images/Logo-ER(3).png"
									alt="{{ producto?.amigable }}">
							</div>
							<div class="col-5 col-lg-6">
								<h6 style="-webkit-box-orient: vertical;">{{
									detalle.producto?.nombre }}</h6>
								<span>x<span>{{ detalle.cantidad }}</span></span>
							</div>
							<div class="col-4 col-lg-3">
								<p>
									$<span>{{ detalle.monto.toString().substring(0,
										detalle.monto.toString().indexOf('.')) }},<sup>{{
											detalle.monto.toString().substring(detalle.monto.toString().indexOf('.')
											+ 1) }}</sup>
									</span>
								</p>
							</div>
						</div>
						<div class="row p-character">
							<div class="form-row align-items-center">
								<div class="col-10">
									<input type="text" class="form-control"
										placeholder="Color|Tamaño|Medida|Otras"
										[(ngModel)]="detalle.observacion">
								</div>
								<div class="col-2">
									<button type="submit" class="btn"
										(click)="agregarObservacion(detalle)">
										<span class="lnr lnr-pencil"></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="empty-c" *ngIf="carrito.length > 0">
					<button data-toggle="modal" data-target="#modalVaciar"
						class="btn ept" (click)="abrirModalVaciar()">
						<span class="lnr lnr-trash"></span>Vaciar Carrito
					</button>
				</div>
				<div class="t-chart" *ngIf="carrito.length > 0">
					<p>
						Total: <span>$<span>{{ totalCarrito }}</span></span>
					</p>
					<a href="/checkout" *ngIf="logueado != null"><button
							type="button" class="btn btn-secondary">Finalizar compra</button></a>
					<a href="/login" *ngIf="logueado == null"><button type="button"
							class="btn btn-secondary">Finalizar compra</button></a>
				</div>
				<button type="button" class="btn btn-primary medios"
					data-toggle="modal" data-target="#exampleModal">Medios de
					Pago</button>
				<div class="publicidad">
					<div id="carouselExampleSlidesOnly" class="carousel slide"
						data-ride="carousel">
						<div class="carousel-inner">
							<div class="carousel-item container"
								*ngFor="let oferta of ofertas[0]; index as i"
								[ngClass]="{ 'active': i == 0 }">
								<div class="row">
									<div class="col-6 img-slide">
										<a href="/producto/{{ oferta.producto.amigable }}"> <img
											class="d-block w-100 img-fluid"
											*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
											[src]="pathImagen(oferta.producto?.foto)" alt="First slide" onerror="this.src='/assets/images/Logo-ER(3).png'">
											<img class="d-block w-100 img-fluid"
											*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
											src="/assets/images/Logo-ER(3).png" alt="First slide">
										</a>
									</div>
									<div class="col-6 slide-info">
										<div class="slide-p">
											<a href="/producto/{{ oferta.producto.amigable }}">
												<h6 style="-webkit-box-orient: vertical;">{{
													oferta.producto.nombre }}</h6>
											</a>
										</div>
										<p>
											$<span>{{ oferta.monto }}</span>
										</p>
                    <div
                      *ngIf="oferta.producto?.stock > 0 ; then productoConStock else productoSinStock">
                    </div>

                    <ng-template #productoConStock>
                      <a style="cursor: pointer;"
                         (click)="agregarCarritoOferta(oferta)"
                         class="buy-c d-none d-lg-block"><span
                        class="lnr lnr-cart"></span>Comprar</a>
                    </ng-template>

                    <ng-template #productoSinStock>
                      <p class="btn-agotado">AGOTADO!</p>
                    </ng-template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="publicidad-custom container"
				*ngIf="banner != null && carrito.length > 0">
				<div class="row">
					<a href="https://{{ banner?.link }}"><img class="img-fluid"
						[src]="pathImagen(banner?.pathImagen)" alt=""></a>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
	aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">En 12 cuotas con
					tarjetas</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<img class="img-fluid" src="./assets/images/tarjetas.png"
					alt="tarjetas">
				<p>Ingresas los datos de tu tarjeta de crédito una sola vez. En
					las compras siguientes solo te pediremos el código de seguridad.</p>
				<a href="https://www.mercadopago.com.ar/cuotas">Bancos que
					ofrecen cuotas sin interés</a>
				<div class="row">
					<img class="img-fluid" src="./assets/images/lnr-clock.svg"
						alt="entrega">
					<p>Acreditación instantánea.</p>
				</div>
				<div class="row">
					<img class="img-fluid" src="./assets/images/truck.svg"
						alt="entrega">
					<p>Entrega del pedido dentro de los 2 días hábiles de realizado
						el pago.</p>
				</div>

				<h5>En efectivo en Puntos de Pagos</h5>
				<img class="img-fluid" src="./assets/images/bancos.png" alt="bancos">
				<p>
					Al momento de pagar te diremos como hacerlo en cualquier sucursal
					de <b>Pago Fácil, Rápipago, Provincia Pagos</b> o en algun cajero
					automático de <b>Banelco, Red Link o Home Banking</b> adherido.
				</p>
				<div class="row">
					<img class="img-fluid" src="./assets/images/lnr-clock.svg"
						alt="entrega">
					<p>Acreditación de 1 a 3 días hábiles</p>
				</div>
				<div class="row">
					<img class="img-fluid" src="./assets/images/truck.svg"
						alt="entrega">
					<p>Entrega del pedido dentro de los 5 días hábiles de realizado
						el pago.</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>

<button #botonAlerta style="display: none;" type="button"
	data-toggle="modal" data-target="#modalAlerta"></button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog"
	aria-labelledby="modalAlertaLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">

				<button #cerrarAlerta type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">{{ mensaje }}</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

			</div>
		</div>
	</div>
</div>

<button #botonModalEliminar style="display: none;" type="button"
	data-toggle="modal" data-target="#modalEliminar"></button>

<div class="modal fade modal-mp" id="modalEliminar" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button #cerrarModalEliminar type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">Está a punto de eliminar
				este item de su carrito de compras.</div>
			<div class="modal-body">
				<div class="botones-pago">
					<button class="btn btn-primary btn-pago"
						(click)="cancelarEliminar()">Cancelar</button>
					<button class="btn btn-primary btn-pago"
						(click)="eliminarCarrito()">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<button #botonModalVaciar style="display: none;" type="button"
	data-toggle="modal" data-target="#modalVaciar"></button>

<div class="modal fade modal-mp" id="modalVaciar" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button #cerrarModalVaciar type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">Está a punto de vaciar su
				carrito de compras.</div>
			<div class="modal-body">
				<div class="botones-pago">
					<button class="btn btn-primary btn-pago" (click)="cancelarVaciar()">Cancelar</button>
					<button class="btn btn-primary btn-pago" (click)="vaciarCarrito()">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>

