import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Producto } from 'src/app/entidades/producto';
import { Cliente } from 'src/app/entidades/cliente';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductoServicio } from 'src/app/servicios/producto.servicio';
import { DetalleVenta } from 'src/app/entidades/detalle-venta';
import { VentaServicio } from 'src/app/servicios/venta.servicio';
import { Oferta } from 'src/app/entidades/oferta';
import { BannerServicio } from 'src/app/servicios/banner.service';
import { Banner } from 'src/app/entidades/banner';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  logueado: Cliente = null;

  totalCarrito: string = null;

  carrito: DetalleVenta[] = [];
  productos: Producto[] = [];

  totalPaginas = 0;
  actualPagina = 0;

  arrayPaginas: number[] = [];

  @ViewChild("botonAlerta") botonAlerta: ElementRef;
  @ViewChild("cerrarAlerta") cerrarAlerta: ElementRef;

  @ViewChild("botonModalEliminar") botonModalEliminar: ElementRef;
  @ViewChild("cerrarModalEliminar") cerrarModalEliminar: ElementRef;

  @ViewChild("botonModalVaciar") botonModalVaciar: ElementRef;
  @ViewChild("cerrarModalVaciar") cerrarModalVaciar: ElementRef;

  detalleEliminar: DetalleVenta;
  indexEliminar: number;

  mensaje: string = null;

  ofertas: Oferta[] = [];

  idDestacado: string = null;
  productoDestacado: Producto = null;

  banner: Banner = null;

  constructor(private productoServicio: ProductoServicio, private ventaServicio: VentaServicio, private router: Router,
    private bannerServicio: BannerServicio, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (localStorage.getItem('logueado') != null && localStorage.getItem('logueado') != '') {
      this.logueado = JSON.parse(localStorage.getItem('logueado'));
    }
    if (this.activatedRoute.snapshot.queryParams['term'] != null) {
      localStorage.removeItem('categoriaId');
      localStorage.removeItem('idDestacado');
      localStorage.removeItem('nombreBusqueda');

      let termino = this.activatedRoute.snapshot.queryParams['term'].replace('-', ' ');
      localStorage.setItem('nombreBusqueda', termino);
    }
    if (this.activatedRoute.snapshot.queryParams['categoria_id'] != null) {
      localStorage.removeItem('categoriaId');
      localStorage.removeItem('idDestacado');
      localStorage.removeItem('nombreBusqueda');

      let id = this.activatedRoute.snapshot.queryParams['categoria_id'];
      localStorage.setItem('categoriaId', id);
    }
    if (this.activatedRoute.snapshot.queryParams['id'] != null) {
      localStorage.removeItem('idDestacado');
      localStorage.removeItem('categoriaId');

      let id = this.activatedRoute.snapshot.queryParams['id'];
      localStorage.setItem('idDestacado', id);
      if (localStorage.getItem('nombreBusqueda') == null) {
        localStorage.setItem('nombreBusqueda', '');
      }
    }
    if (localStorage.getItem('categoriaId') != null) {
      this.productoServicio.pageProductosPorCategoria(localStorage.getItem('categoriaId'), this.actualPagina).subscribe(res => {
        if (res['content'].length > 0) {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            console.log("1" + p.precioOferta)
            p.cantidad = 1;
          });
          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
        } else {
          this.router.navigate(['/no-results']);
        }
      }, error => {
        this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else if (localStorage.getItem('nombreBusqueda') != null) {
      if (localStorage.getItem('idDestacado') != null) {
        this.idDestacado = localStorage.getItem('idDestacado');
        localStorage.removeItem('idDestacado');
      }
      this.productoServicio.pageProductosPorNombre(localStorage.getItem('nombreBusqueda'), 0).subscribe(res => {
        if (res['content'].length > 0) {
          res['content'].forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
            if (this.idDestacado == p.id) {
              this.productoDestacado = p;
            }
            this.productos.push(p);
          })
          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
        } else {
          this.router.navigate(['/no-results']);
        }
      }, error => {
        this.mensaje = "Ha ocurrido un error al momento de listar las categorías.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.productoServicio.listarCategorias().subscribe(res => {
        localStorage.setItem('categoriaId', res[0].id);
        this.productoServicio.pageProductosPorCategoria(localStorage.getItem('categoriaId'), this.actualPagina).subscribe(res => {
          if (res['content'].length > 0) {
            this.productos = res['content'];
            this.productos.forEach(p => {
              p.precio1 = this.ordenarDecimales(p.precio1);
              p.precio2 = this.ordenarDecimales(p.precio2);
              p.precio3 = this.ordenarDecimales(p.precio3);
              p.precio4 = this.ordenarDecimales(p.precio4);
              p.precioOferta ;
              p.cantidad = 1;
            });
            let i = this.actualPagina;
            this.arrayPaginas = [];
            if (res['totalPages'] > 8) {
              if (res['totalPages'] - this.actualPagina > 3) {
                for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                  if (this.arrayPaginas.length < 8) {
                    if (i - 3 > 0) {
                      this.arrayPaginas.push(i - 3);
                    }
                  }
                }
              } else {
                i = i -8;
                for (i; i < this.actualPagina; i++) {
                  this.arrayPaginas.push(i + 1);
                }
              }
            } else {
              for (let i = 0; i < res['totalPages']; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
            this.totalPaginas = res['totalPages'];
          } else {
            this.router.navigate(['/no-results']);
          }
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      }, error => {
        this.mensaje = "Ha ocurrido un error al momento de listar las categorías.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    }
    this.obtenerCarrito();
    this.obtenerOfertas();
    this.obtenerBanner();

  }

  obtenerBanner() {
    this.bannerServicio.obtenerBanner().subscribe(res => {
      this.banner = res;
    }, error => {
      this.banner = null;
    });
  }

  cambiarPagina(pagina) {
    if (this.actualPagina != (pagina - 1)) {
      this.actualPagina = (pagina - 1);
      if (localStorage.getItem('categoriaId') != null) {
        this.productoServicio.pageProductosPorCategoria(localStorage.getItem('categoriaId'), this.actualPagina).subscribe(res => {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
          });
          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
          window.scroll(0, 0);
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      } else if (localStorage.getItem('nombreBusqueda') != null) {
        this.productoServicio.pageProductosPorNombre(localStorage.getItem('nombreBusqueda'), this.actualPagina).subscribe(res => {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
          });
          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
          window.scroll(0, 0);
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      }
    }
  }

  siguientePagina() {
      this.actualPagina = this.totalPaginas - 1;
      if (localStorage.getItem('categoriaId') != null) {
        this.productoServicio.pageProductosPorCategoria(localStorage.getItem('categoriaId'), this.actualPagina).subscribe(res => {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
          });

          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
          window.scroll(0, 0);
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      } else if (localStorage.getItem('nombreBusqueda') != null) {
        this.productoServicio.pageProductosPorNombre(localStorage.getItem('nombreBusqueda'), this.actualPagina).subscribe(res => {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
          });

          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
          window.scroll(0, 0);
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
    }
  }

  anteriorPagina() {
      this.actualPagina = 0;
      if (localStorage.getItem('categoriaId') != null) {
        this.productoServicio.pageProductosPorCategoria(localStorage.getItem('categoriaId'), this.actualPagina).subscribe(res => {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
          });
          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
          window.scroll(0, 0);
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      } else if (localStorage.getItem('nombreBusqueda') != null) {
        this.productoServicio.pageProductosPorNombre(localStorage.getItem('nombreBusqueda'), this.actualPagina).subscribe(res => {
          this.productos = res['content'];
          this.productos.forEach(p => {
            p.precio1 = this.ordenarDecimales(p.precio1);
            p.precio2 = this.ordenarDecimales(p.precio2);
            p.precio3 = this.ordenarDecimales(p.precio3);
            p.precio4 = this.ordenarDecimales(p.precio4);
            p.precioOferta;
            p.cantidad = 1;
          });
          let i = this.actualPagina;
          this.arrayPaginas = [];
          if (res['totalPages'] > 8) {
            if (res['totalPages'] - this.actualPagina > 3) {
              for (i; i < res['totalPages'] + (res['totalPages'] - this.actualPagina); i++) {
                if (this.arrayPaginas.length < 8) {
                  if (i - 3 > 0) {
                    this.arrayPaginas.push(i - 3);
                  }
                }
              }
            } else {
              i = i -8;
              for (i; i < this.actualPagina; i++) {
                this.arrayPaginas.push(i + 1);
              }
            }
          } else {
            for (let i = 0; i < res['totalPages']; i++) {
              this.arrayPaginas.push(i + 1);
            }
          }
          this.totalPaginas = res['totalPages'];
          window.scroll(0, 0);
        }, error => {
          this.mensaje = "Ha ocurrido un error al buscar los resultados. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
    }
  }

  obtenerCarrito() {
    if (this.logueado != null) {
      this.ventaServicio.getCarrito(this.logueado.id).subscribe(res => {
        if (res != null) {
          this.carrito = res['detalleVenta'];
          this.carrito.forEach(i => {
            i.producto.precio1 = this.ordenarDecimales(i.producto.precio1);
            i.producto.precio2 = this.ordenarDecimales(i.producto.precio2);
            i.producto.precio3 = this.ordenarDecimales(i.producto.precio3);
            i.producto.precio4 = this.ordenarDecimales(i.producto.precio4);
            i.producto.precioOferta;
            i.monto = this.ordenarDecimales(i.monto);
          });
          this.totalCarrito = this.ordenarDecimales(res['monto']);
        }
      }, error => {
        this.mensaje = "Hubo un error al obtener el carrito. Intente nuevamente más tarde";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      if (localStorage.getItem('carrito') != null) {
        this.carrito = JSON.parse(localStorage.getItem('carrito'));
        let monto = 0;
        this.carrito.forEach(i => {
          monto = +i.monto + monto;
        });
        this.totalCarrito = this.ordenarDecimales('' + monto);
      }
    }
  }

  pathImagen(path: string) {
    return this.productoServicio.getImagen(path);
  }

  agregarCarrito(producto) {
    if (producto.cantidad > 0) {
      let detalle: DetalleVenta;
      if (this.logueado != null) {
        switch (this.logueado.lista) {
          case 1:
            detalle = {
              id: null,
              producto: producto,
              cantidad: producto.cantidad,
              monto: ('' + (producto.precio1 * producto.cantidad)).match(/^-?\d+(?:\.\d{0,2})?/)[0],
              observacion: null
            }
            break;
          case 2:
             let monto;
             if (producto.precioOferta !== null ) {
               monto = ('' + (producto.precioOferta * producto.cantidad)).match(/^-?\d+(?:\.\d{0,2})?/)[0];
             } else {
               monto = ('' + (producto.precio2 * producto.cantidad)).match(/^-?\d+(?:\.\d{0,2})?/)[0];
             }

             detalle = {
               id: null,
               producto: producto,
               cantidad: producto.cantidad,
               monto: monto,
               observacion: null
             }
             break;
          case 3:
            detalle = {
              id: null,
              producto: producto,
              cantidad: producto.cantidad,
              monto: ('' + (producto.precio3 * producto.cantidad)).match(/^-?\d+(?:\.\d{0,2})?/)[0],
              observacion: null
            }
            break;
          case 4:
            detalle = {
              id: null,
              producto: producto,
              cantidad: producto.cantidad,
              monto: ('' + (producto.precio4 * producto.cantidad)).match(/^-?\d+(?:\.\d{0,2})?/)[0],
              observacion: null
            }
            break;
        }
        this.ventaServicio.crearDetalle(detalle, this.logueado.id).subscribe((res) => {
            this.obtenerCarrito();
            this.mensaje = "Producto agregado!";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
            setTimeout(() => {
              let boton: HTMLElement = this.cerrarAlerta.nativeElement;
              boton.click();
            }, 2000);
          },(error) => {
            if (error.status === 400) {
             this.mensaje = "No hay stock suficiente de este producto.";
            }
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          }
        );
      } else {
        let b: boolean = false;
        this.carrito.forEach(i => {
          if (i.producto.id == producto.id) {
           if (producto.stock >= (producto.cantidad + i.cantidad)){
            i.cantidad = i.cantidad + producto.cantidad;
            if (producto.precioOferta !== null) {
                    let monto = Number(producto.precioOferta * i.cantidad);
                    i.monto = this.ordenarDecimales(monto);
                  } else {
                    let monto = Number(producto.precio2 * i.cantidad);
                    i.monto = this.ordenarDecimales(monto);
                  }

            this.mensaje = "Producto agregado!";
           }else {
            this.mensaje = "No hay stock suficiente de este producto.";
           }
            b = true;
          }
        })
        if (!b) {
        let monto;
         if (producto.precioOferta !== null) {
           monto = ('' + (producto.precioOferta)).match(/^-?\d+(?:\.\d{0,2})?/)[0];
         } else {
           monto = ('' + (producto.precio2)).match(/^-?\d+(?:\.\d{0,2})?/)[0];
         }
          detalle = {
            id: null,
            producto: producto,
            cantidad: producto.cantidad,
            monto: monto,
            observacion: null
          }
          if (producto.stock >= producto.cantidad){
           this.carrito.push(detalle);
           this.mensaje = "Producto agregado!";
          }else {
           this.mensaje = "No hay stock suficiente de este producto.";
          }
        }
        localStorage.setItem('carrito', JSON.stringify(this.carrito));
        let monto = 0;
        this.carrito.forEach(i => {
          monto = +i.monto + monto;
        });
        this.totalCarrito = this.ordenarDecimales('' + monto);
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
        setTimeout(() => {
          let boton: HTMLElement = this.cerrarAlerta.nativeElement;
          boton.click();
        }, 2000);
      }
    } else {
      this.mensaje = "Especifique una cantidad mayor a cero.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

  eliminarCarrito() {
    if (this.logueado != null) {
      this.ventaServicio.eliminarDetalle(this.detalleEliminar.id, this.logueado.id).subscribe(res => {
        this.obtenerCarrito();
        let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
        alert.click();
      }, error => {
        let modal: HTMLElement = this.cerrarModalEliminar.nativeElement;
        modal.click();
        this.mensaje = "Hubo un error al eliminar item del carrito. Intente nuevamente más tarde";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.carrito.splice(this.indexEliminar, 1);
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      let monto = 0;
      this.carrito.forEach(i => {
        monto = +i.monto + monto;
      });
      this.totalCarrito = this.ordenarDecimales('' + monto);
      let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
      alert.click();
    }
  }

  vaciarCarrito() {
    if (this.logueado != null) {
      this.ventaServicio.vaciarCarrito(this.logueado.id).subscribe(res => {
        this.obtenerCarrito();
        let alert: HTMLElement = this.cerrarModalVaciar.nativeElement;
        alert.click();
      }, error => {
        let modal: HTMLElement = this.cerrarModalVaciar.nativeElement;
        modal.click();
        this.mensaje = "Hubo un error al vaciar el carrito. Intente nuevamente más tarde";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.carrito = [];
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      this.totalCarrito = this.ordenarDecimales('0');
      let alert: HTMLElement = this.cerrarModalVaciar.nativeElement;
      alert.click();
    }
  }

  ordenarDecimales(valor) {
    let numero;
    if (Number(valor) % 1 != 0) {
      let decimales: string = valor.toString().substring(valor.toString().indexOf('.') + 1);
      if (decimales.length == 1) {
        decimales = decimales + '0';
      } else if (decimales.length >= 2) {
        decimales = decimales.substring(0, 2);
      }
      numero = valor.toString().substring(0, valor.toString().indexOf('.')) + '.' + decimales;
    } else {
      numero = valor + '.00';
    }
    return numero;
  }

  agregarObservacion(detalle) {
    if (this.logueado != null) {
      this.ventaServicio.agregarObservacion(detalle.id, detalle.observacion).subscribe(res => {
        this.mensaje = "Observación agregada al detalle de venta.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }, error => {
        this.mensaje = "Hubo un error al agregar su observación al detalle de venta.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      let i = 0;
      this.carrito.forEach(d => {
        if (d.id == detalle.id) {
          d.observacion = detalle.observacion;
        }
        i++;
      })
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      this.mensaje = "Observación agregada al detalle de venta.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

  obtenerOfertas() {
    this.ofertas = [];
    this.productoServicio.listarOfertas().subscribe(res => {
      res.forEach(o => {
        o.producto.precio1 = this.ordenarDecimales(o.producto.precio1);
        o.producto.precio2 = this.ordenarDecimales(o.producto.precio2);
        o.producto.precio3 = this.ordenarDecimales(o.producto.precio3);
        o.producto.precio4 = this.ordenarDecimales(o.producto.precio4);
        o.monto = this.ordenarDecimales(o.monto);
        this.ofertas.push(o);
      });
    }, error => {
      this.mensaje = "Hubo un error al obtener las ofertas. Intente nuevamente más tarde";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    })
  }

  agregarCarritoOferta(oferta) {
  console.log('search')
    let detalle: DetalleVenta;
    if (this.logueado != null) {
      detalle = {
        id: null,
        producto: oferta.producto,
        cantidad: 1,
        monto: ('' + oferta.monto).match(/^-?\d+(?:\.\d{0,2})?/)[0],
        observacion: null
      }
      this.ventaServicio.crearDetalle(detalle, this.logueado.id).subscribe((res) => {
        this.obtenerCarrito();
        this.mensaje = "Producto agregado!";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
        setTimeout(() => {
          let boton: HTMLElement = this.cerrarAlerta.nativeElement;
          boton.click();
        }, 2000);
      },(error) => {
        if (error.status === 400) {
         this.mensaje = "No hay stock suficiente de este producto.";
        }
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    );
    } else {
      let b: boolean = false;
      this.carrito.forEach(i => {
        if (i.producto.id == oferta.producto.id) {
          if (oferta.producto.stock >= (i.cantidad + 1)){
            i.cantidad = i.cantidad + 1;
            let monto = Number(i.monto) + Number(oferta.monto);
            i.monto =  this.ordenarDecimales(monto);
            this.mensaje = "Producto agregado!";
          }else {
           this.mensaje = "No hay stock suficiente de este producto.";
          }
          b = true;
        }
      })
      if (!b) {
        detalle = {
          id: null,
          producto: oferta.producto,
          cantidad: 1,
          monto: ('' + oferta.monto).match(/^-?\d+(?:\.\d{0,2})?/)[0],
          observacion: null
        }
          if (oferta.producto.stock >= oferta.producto.cantidad){
                   this.carrito.push(detalle);
                   this.mensaje = "Producto agregado!";
                  }else {
                   this.mensaje = "No hay stock suficiente de este producto.";
                  }
      }
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      let monto = 0;
      this.carrito.forEach(i => {
        monto = +i.monto + monto;
      });
      this.totalCarrito = this.ordenarDecimales('' + monto);
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
      setTimeout(() => {
        let boton: HTMLElement = this.cerrarAlerta.nativeElement;
        boton.click();
      }, 2000);
    }
  }

  cancelarEliminar() {
    this.detalleEliminar = null;
    this.indexEliminar = null;
    let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
    alert.click();
  }

  abrirModalEliminar(detalle, index) {
    this.detalleEliminar = detalle;
    this.indexEliminar = index;
    let alert: HTMLElement = this.botonModalEliminar.nativeElement;
    alert.click();
  }

  abrirModalVaciar() {
    let alert: HTMLElement = this.botonModalVaciar.nativeElement;
    alert.click();
  }

  cancelarVaciar() {
    let alert: HTMLElement = this.cerrarModalVaciar.nativeElement;
    alert.click();
  }

}
