import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';

@Component({
  selector: 'app-restore-pass',
  templateUrl: './restore-pass.component.html',
  styleUrls: ['./restore-pass.component.scss']
})
export class RestorePassComponent implements OnInit {

  @ViewChild("inputEmail") inputEmail: ElementRef;

  @ViewChild("botonAlerta") botonAlerta: ElementRef;

  mensaje: string = null;

  constructor(private clienteServicio: ClienteServicio) { }

  ngOnInit() {
  }

  restablecerClave() {
    if (this.inputEmail.nativeElement.value != '') {
      if (this.validarEmail(this.inputEmail.nativeElement.value)) {
        this.clienteServicio.restablecerClave(this.inputEmail.nativeElement.value).subscribe(res => {
          this.mensaje = "Revise su casilla de correo para recuperar su contraseña.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        }, error => {
          this.mensaje = "Ha ocurrido un error al momento de recuperar su contraseña. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      } else {
        this.mensaje = "Ingrese una cuenta de email válida.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    } else {
      this.mensaje = "Complete el campo de email.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

  validarEmail(email) {
    let r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return r.test(email);
  }

}
