import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login = true;

  @ViewChild("inputEmail") inputEmail: ElementRef;
  @ViewChild("inputEmailM") inputEmailM: ElementRef;
  @ViewChild("botonAlerta") botonAlerta: ElementRef;

  mensaje: string = null;

  loginForm: FormGroup;

  constructor(private router: Router, private clienteServicio: ClienteServicio, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams['t'] != null) {
      this.clienteServicio.recuperarCuenta(this.activatedRoute.snapshot.queryParams['t']).subscribe(res => {}, error => {
        this.mensaje = "Error al recuperar cuenta. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    }
    this.inicializarForm();
    if (localStorage.getItem('logueado') != null) {
      this.router.navigate(['/']);
    }
  }

  inicializarForm() {
    this.loginForm = new FormGroup({
      'usuario': new FormControl("", [
        Validators.required
      ]),
      'clave': new FormControl("", [
        Validators.required
      ])
    });
  }

  loginCliente() {
    if (this.loginForm.get('usuario').value != '' && this.loginForm.get('clave').value != '') {
      let usuario = this.loginForm.get('usuario').value;
      let clave = this.loginForm.get('clave').value;
      this.clienteServicio.login(usuario, clave).subscribe(res => {
        if (res.fechaAlta == null) {
          this.mensaje = "Debe validar su cuenta de email.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        } else {
          localStorage.setItem('logueado', JSON.stringify(res));
          this.router.navigate(['/']);
        }
      }, error => {
        this.mensaje = "Email o contraseña incorrecto/s.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.mensaje = "Complete todos los campos.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }    
  }

  irRegistro() {
    this.login = false;
  }

  registrarse() {
    if (this.inputEmail.nativeElement.value != '') {
      if (this.validarEmail(this.inputEmail.nativeElement.value)) {
        this.clienteServicio.buscarPorEmail(this.inputEmail.nativeElement.value).subscribe(res => {
          if (res == null) {
            localStorage.setItem('emailRegistro', this.inputEmail.nativeElement.value);
            this.router.navigate(['/registry']);
          } else {
            this.mensaje = "El email ingresado ya está en uso.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          }
        }, error => {
          this.mensaje = "Ocurrió un error al validar el mail. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        });
      } else {
        this.mensaje = "Ingrese una cuenta de email válida.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      } 
    } else {
      this.mensaje = "Complete el campo del email a registrarse.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }    
  }

  registrarseM() {
    if (this.inputEmailM.nativeElement.value != '') {
      if (this.validarEmail(this.inputEmailM.nativeElement.value)) {
        this.clienteServicio.buscarPorEmail(this.inputEmailM.nativeElement.value).subscribe(res => {
          if (res == null) {
            localStorage.setItem('emailRegistro', this.inputEmailM.nativeElement.value);
            this.router.navigate(['/registry']);
          } else {
            this.mensaje = "El email ingresado ya está en uso.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          }
        }, error => {
          this.mensaje = "Ocurrió un error al validar el mail. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        });
      } else {
        this.mensaje = "Ingrese una cuenta de email válida.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      } 
    } else {
      this.mensaje = "Complete el campo del email a registrarse.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }   
  }

  validarEmail(email) {
    let r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return r.test(email);
  }

}
