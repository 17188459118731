import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Banner } from '../entidades/banner';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerServicio {

    public server: string = environment.baseUrl + '/api/banner';

    constructor(private http: HttpClient) {}

    obtenerBanner(): Observable<Banner> {
        return this.http.get<Banner>(this.server);
    }


}