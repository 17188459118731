import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Cliente } from 'src/app/entidades/cliente';
import { DetalleVenta } from 'src/app/entidades/detalle-venta';
import { VentaServicio } from 'src/app/servicios/venta.servicio';
import { ProductoServicio } from 'src/app/servicios/producto.servicio';
import { Producto } from 'src/app/entidades/producto';
import { Oferta } from 'src/app/entidades/oferta';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';
import { ActivatedRoute, Router } from '@angular/router';
import { Banner } from 'src/app/entidades/banner';
import { BannerServicio } from 'src/app/servicios/banner.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  logueado: Cliente = null;

  mensaje: string = null;

  totalCarrito: string = null;

  @ViewChild("botonAlerta") botonAlerta: ElementRef;
  @ViewChild("cerrarAlerta") cerrarAlerta: ElementRef;

  @ViewChild("botonModalEliminar") botonModalEliminar: ElementRef;
  @ViewChild("cerrarModalEliminar") cerrarModalEliminar: ElementRef;

  @ViewChild("botonModalVaciar") botonModalVaciar: ElementRef;
  @ViewChild("cerrarModalVaciar") cerrarModalVaciar: ElementRef;

  detalleEliminar: DetalleVenta;
  indexEliminar: number;

  carrito: DetalleVenta[] = [];

  novedades: Producto[][] = [];
  masComprados: Producto[][] = [];
  ofertas: Oferta[][] = [];

  novedadesMobile: Producto[][] = [];
  masCompradosMobile: Producto[][] = [];
  ofertasMobile: Oferta[][] = [];

  banner: Banner = null;

  constructor(private ventaServicio: VentaServicio, private productoServicio: ProductoServicio, private activatedRoute: ActivatedRoute,
    private clienteServicio: ClienteServicio, private router: Router, private bannerServicio: BannerServicio) { }

  ngOnInit() {
    if (localStorage.getItem('logueado') != null && localStorage.getItem('logueado') != '') {
      this.logueado = JSON.parse(localStorage.getItem('logueado'));
    }
    if (this.activatedRoute.snapshot.queryParams['t'] != null) {
      this.clienteServicio.validarCuenta(this.activatedRoute.snapshot.queryParams['t']).subscribe(res => {
        this.mensaje = "Usted ha validado su cuenta correctamente.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
      }, error => {
        this.mensaje = "Ha ocurrido un error al validar su cuenta. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    }
    if (this.activatedRoute.snapshot.queryParams['v'] != null) {
      this.ventaServicio.procesarPagoEfectivo(this.activatedRoute.snapshot.queryParams['v']).subscribe(res => {
        this.mensaje = "Su solicitud ha sido procesada. Estamos preparando su pedido.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
        setTimeout(() => {
          this.router.navigate(['/']);
          this.obtenerCarrito();
        }, 3000);
      }, error => {
        this.mensaje = "Ha ocurrido un error al procesar el pago en efectivo. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.recuperarCarrito();
    }
    this.obtenerNovedades();
    this.obtenerMasComprados();
    this.obtenerOfertas();
    this.obtenerBanner();
  }

  recuperarCarrito() {
    if (localStorage.getItem('carrito') != null && this.logueado != null) {
      let carrito: any[] = JSON.parse(localStorage.getItem('carrito'));
      this.ventaServicio.recuperarCarrito(carrito, this.logueado.id).subscribe(res => {
        this.carrito = res['detalleVenta'];
        this.carrito.forEach(i => {
          i.monto = this.ordenarDecimales(i.monto);
        });
        this.totalCarrito = this.ordenarDecimales(res['monto']);
        localStorage.removeItem('carrito');
      }, error => {
        this.mensaje = "Ha ocurrido un error al obtener carrito de compras. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.obtenerCarrito();
    }
  }

  obtenerBanner() {
    this.bannerServicio.obtenerBanner().subscribe(res => {
      this.banner = res;
    }, error => {
      this.banner = null;
    });
  }

  obtenerNovedades() {
    this.novedades[1] = [];
    this.novedades[0] = [];
    this.novedadesMobile[0] = [];
    this.novedadesMobile[1] = [];
    this.novedadesMobile[2] = [];
    this.novedadesMobile[3] = [];
    this.productoServicio.listarNovedades().subscribe(res => {
      let i = 0;
      res.forEach(p => {
        if (i < 4) {
          if (i < 2) {
            this.novedadesMobile[0].push(p);
          } else {
            this.novedadesMobile[1].push(p);
          }
          this.novedades[0].push(p);
        } else {
          if (i < 6) {
            this.novedadesMobile[2].push(p);
          } else {
            this.novedadesMobile[3].push(p);
          }
          this.novedades[1].push(p);
        }
        p.precio1 = this.ordenarDecimales(p.precio1);
        p.precio2 = this.ordenarDecimales(p.precio2);
        p.precio3 = this.ordenarDecimales(p.precio3);
        p.precio4 = this.ordenarDecimales(p.precio4);
        i++;
      });
    }, error => {
      this.mensaje = "Hubo un error al obtener las novedades. Intente nuevamente más tarde";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    })
  }

  obtenerMasComprados() {
    this.masComprados[1] = [];
    this.masComprados[0] = [];
    this.masCompradosMobile[0] = [];
    this.masCompradosMobile[1] = [];
    this.masCompradosMobile[2] = [];
    this.masCompradosMobile[3] = [];
    this.productoServicio.listarMasComprados().subscribe(res => {
      let i = 0;
      res.forEach(p => {
        if (i < 4) {
          if (i < 2) {
            this.masCompradosMobile[0].push(p);
          } else {
            this.masCompradosMobile[1].push(p);
          }
          this.masComprados[0].push(p);
        } else {
          if (i < 6) {
            this.masCompradosMobile[2].push(p);
          } else {
            this.masCompradosMobile[3].push(p);
          }
          this.masComprados[1].push(p);
        }
        p.precio1 = this.ordenarDecimales(p.precio1);
        p.precio2 = this.ordenarDecimales(p.precio2);
        p.precio3 = this.ordenarDecimales(p.precio3);
        p.precio4 = this.ordenarDecimales(p.precio4);
        i++;
      });
    }, error => {
      this.mensaje = "Hubo un error al obtener productos más comprados. Intente nuevamente más tarde";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    })
  }

  obtenerOfertas() {
    this.ofertas[1] = [];
    this.ofertas[0] = [];
    this.ofertasMobile[0] = [];
    this.ofertasMobile[1] = [];
    this.ofertasMobile[2] = [];
    this.ofertasMobile[3] = [];
    this.productoServicio.listarOfertas().subscribe(res => {
      let i = 0;
      res.forEach(o => {
        if (i < 4) {
          if (i < 2) {
            this.ofertasMobile[0].push(o);
          } else {
            this.ofertasMobile[1].push(o);
          }
          this.ofertas[0].push(o);
        } else {
          if (i < 6) {
            this.ofertasMobile[2].push(o);
          } else {
            this.ofertasMobile[3].push(o);
          }
          this.ofertas[1].push(o);
        }
        o.producto.precio1 = this.ordenarDecimales(o.producto.precio1);
        o.producto.precio2 = this.ordenarDecimales(o.producto.precio2);
        o.producto.precio3 = this.ordenarDecimales(o.producto.precio3);
        o.producto.precio4 = this.ordenarDecimales(o.producto.precio4);
        o.monto = this.ordenarDecimales(o.monto);
        i++;
      });
    }, error => {
      this.mensaje = "Hubo un error al obtener las ofertas. Intente nuevamente más tarde";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    })
  }

  obtenerCarrito() {
    if (this.logueado != null) {
      this.ventaServicio.getCarrito(this.logueado.id).subscribe(res => {
        if (res != null) {
          this.carrito = res['detalleVenta'];
          this.carrito.forEach(i => {
            i.monto = this.ordenarDecimales(i.monto);
          });
          this.totalCarrito = this.ordenarDecimales(res['monto']);
        }
      }, error => {
        this.mensaje = "Hubo un error al obtener el carrito. Intente nuevamente más tarde";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      if (localStorage.getItem('carrito') != null) {
        this.carrito = JSON.parse(localStorage.getItem('carrito'));
        let monto = 0;
        this.carrito.forEach(i => {
          monto = +i.monto + monto;
        });
        this.totalCarrito = this.ordenarDecimales('' + monto);
      }
    }
  }

  ordenarDecimales(valor) {
    let numero;
    if (Number(valor) % 1 != 0) {
      let decimales: string = valor.toString().substring(valor.toString().indexOf('.') + 1);
      if (decimales.length == 1) {
        decimales = decimales + '0';
      } else if (decimales.length >= 2) {
        decimales = decimales.substring(0, 2);
      }
      numero = valor.toString().substring(0, valor.toString().indexOf('.')) + '.' + decimales;
    } else {
      numero = valor + '.00';
    }
    return numero;
  }

  agregarObservacion(detalle) {
    if (this.logueado != null) {
      this.ventaServicio.agregarObservacion(detalle.id, detalle.observacion).subscribe(res => {
        this.mensaje = "Observación agregada al detalle de venta.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }, error => {
        this.mensaje = "Hubo un error al agregar su observación al detalle de venta.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      let i = 0;
      this.carrito.forEach(d => {
        if (d.id == detalle.id) {
          d.observacion = detalle.observacion;
        }
        i++;
      })
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      this.mensaje = "Observación agregada al detalle de venta.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

  abrirModalVaciar() {
    let alert: HTMLElement = this.botonModalVaciar.nativeElement;
    alert.click();
  }

  cancelarVaciar() {
    let alert: HTMLElement = this.cerrarModalVaciar.nativeElement;
    alert.click();
  }

  vaciarCarrito() {
    if (this.logueado != null) {
      this.ventaServicio.vaciarCarrito(this.logueado.id).subscribe(res => {
        this.obtenerCarrito();
        let alert: HTMLElement = this.cerrarModalVaciar.nativeElement;
        alert.click();
      }, error => {
        this.mensaje = "Hubo un error al vaciar el carrito. Intente nuevamente más tarde";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.carrito = [];
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      this.totalCarrito = this.ordenarDecimales('0');
      let alert: HTMLElement = this.cerrarModalVaciar.nativeElement;
      alert.click();
    }
  }

  cancelarEliminar() {
    this.detalleEliminar = null;
    this.indexEliminar = null;
    let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
    alert.click();
  }

  abrirModalEliminar(detalle, index) {
    this.detalleEliminar = detalle;
    this.indexEliminar = index;
    let alert: HTMLElement = this.botonModalEliminar.nativeElement;
    alert.click();
  }

  eliminarCarrito() {
    if (this.logueado != null) {
      this.ventaServicio.eliminarDetalle(this.detalleEliminar.id, this.logueado.id).subscribe(res => {
        this.obtenerCarrito();
        let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
        alert.click();
      }, error => {
        let modal: HTMLElement = this.cerrarModalEliminar.nativeElement;
        modal.click();
        this.mensaje = "Hubo un error al eliminar item del carrito. Intente nuevamente más tarde";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    } else {
      this.carrito.splice(this.indexEliminar, 1);
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      let monto = 0;
      this.carrito.forEach(i => {
        monto = +i.monto + monto;
      });
      this.totalCarrito = this.ordenarDecimales('' + monto);
      let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
      alert.click();
    }
  }

  agregarCarrito(producto) {
    let detalle: DetalleVenta;
    if (this.logueado != null) {
      switch (this.logueado.lista) {
        case 1:
          detalle = {
            id: null,
            producto: producto,
            cantidad: 1,
            monto: ('' + producto.precio1).match(/^-?\d+(?:\.\d{0,2})?/)[0],
            observacion: null
          }
          break;
        case 2:
          detalle = {
            id: null,
            producto: producto,
            cantidad: 1,
            monto: ('' + producto.precio2).match(/^-?\d+(?:\.\d{0,2})?/)[0],
            observacion: null
          }
          break;
        case 3:
          detalle = {
            id: null,
            producto: producto,
            cantidad: 1,
            monto: ('' + producto.precio3).match(/^-?\d+(?:\.\d{0,2})?/)[0],
            observacion: null
          }
          break;
        case 4:
          detalle = {
            id: null,
            producto: producto,
            cantidad: 1,
            monto: ('' + producto.precio4).match(/^-?\d+(?:\.\d{0,2})?/)[0],
            observacion: null
          }
          break;
      }
      this.ventaServicio.crearDetalle(detalle, this.logueado.id).subscribe((res) => {
            this.obtenerCarrito();
            this.mensaje = "Producto agregado!";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
            setTimeout(() => {
              let boton: HTMLElement = this.cerrarAlerta.nativeElement;
              boton.click();
            }, 2000);
          },(error) => {
            if (error.status === 400) {
             this.mensaje = "No hay stock suficiente de este producto.";
            }
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          }
        );
    } else {
      let b: boolean = false;
      console.log(this.carrito)
     this.carrito.forEach(i => {
               if (i.producto.id == producto.id) {
                if (producto.stock >= (1 + i.cantidad)){
                  i.cantidad = i.cantidad + 1;
                  let monto = Number(i.monto) + Number(producto.precio2);
                  i.monto =  this.ordenarDecimales(monto);
                  this.mensaje = "Producto agregado!";
                 }else {
                  this.mensaje = "No hay stock suficiente de este producto.";
                 }
                 b = true;
               }
             })
      if (!b) {
              detalle = {
                id: null,
                producto: producto,
                cantidad: 1,
                monto: ('' + producto.precio2).match(/^-?\d+(?:.\d{0,2})?/)[0],
                observacion: null
              }
              this.carrito.push(detalle);
               this.mensaje = "Producto agregado!";
            }
            localStorage.setItem('carrito', JSON.stringify(this.carrito));
            let monto = 0;
            this.carrito.forEach(i => {
              monto = +i.monto + monto;
            });
            this.totalCarrito = this.ordenarDecimales('' + monto);
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
            setTimeout(() => {
              let boton: HTMLElement = this.cerrarAlerta.nativeElement;
              boton.click();
            }, 2000);
    }
  }

  agregarCarritoOferta(oferta) {
    let detalle: DetalleVenta;
    console.log('index')
    if (this.logueado != null) {
      detalle = {
        id: null,
        producto: oferta.producto,
        cantidad: 1,
        monto: ('' + oferta.monto).match(/^-?\d+(?:\.\d{0,2})?/)[0],
        observacion: null
      }
      this.ventaServicio.crearDetalle(detalle, this.logueado.id).subscribe((res) => {
        this.obtenerCarrito();
        this.mensaje = "Producto agregado!";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
        setTimeout(() => {
          let boton: HTMLElement = this.cerrarAlerta.nativeElement;
          boton.click();
        }, 2000);
      },(error) => {
        if (error.status === 400) {
         this.mensaje = "No hay stock suficiente de este producto.";
        }
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    );
   } else {
      let b: boolean = false;
      this.carrito.forEach(i => {
        if (i.producto.id == oferta.producto.id) {
        if (oferta.producto.stock >= (i.cantidad + 1)){
          i.cantidad = i.cantidad + 1;
          let monto = Number(i.monto) + Number(oferta.monto);
          i.monto =  this.ordenarDecimales(monto);
          this.mensaje = "Producto agregado!";
          }else {
           this.mensaje = "No hay stock suficiente de este producto.";
          }
          b = true;
        }
      })
      if (!b) {
        detalle = {
          id: null,
          producto: oferta.producto,
          cantidad: 1,
          monto: ('' + oferta.monto).match(/^-?\d+(?:\.\d{0,2})?/)[0],
          observacion: null
        }
           this.carrito.push(detalle);
           this.mensaje = "Producto agregado!";
        }
      localStorage.setItem('carrito', JSON.stringify(this.carrito));
      let monto = 0;
      this.carrito.forEach(i => {
        monto = +i.monto + monto;
      });
      this.totalCarrito = this.ordenarDecimales('' + monto);
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
      setTimeout(() => {
        let boton: HTMLElement = this.cerrarAlerta.nativeElement;
        boton.click();
      }, 2000);
    }
  }

  pathImagen(path: string) {
    return this.productoServicio.getImagen(path);
  }

}
