<header class="container-fluid d-none d-md-block">
    <div class="container">
        <nav class="navbar">
            <div class="row w-100">

                <div class="col-3 col-logo">
                    <h1 class="logo"><a href="#"><img class="img-fluid" src="/assets/images/Logo-ER.png"
                                alt=""></a></h1>
                </div>

                <div class="col-6 d-flex flex-row align-items-center">
                    <form class="form-inline ">
                        <div class="input-group">
                            <span class="input-group-text"><img src="/assets/images/Icons-15.svg" alt="icono" class="nav-svg">
                            </span>
                            <input class="form-control" type="search"
                                placeholder="¿Qué producto estás buscando?" aria-label="Search" (input)="buscar($event)" (keypress)="traerResultados($event, null)">

                            <div *ngIf="resultados != null" class="dropdown-menu searcher d-block">
                                <a *ngFor="let producto of resultados" class="dropdown-item" style="cursor: pointer;" (click)="traerResultados(null, producto.id)">{{ producto.nombre }}</a>
                                <a *ngIf="resultados.length == 0" class="dropdown-item" style="cursor: default;" disabled>NO HAY RESULTADOS</a>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-3 col-menu d-flex flex-row align-items-center line-icon">
                    <span class="lnr lnr-users"></span>
                    <a *ngIf="logueado == null" class="s-name" href="/login">Ingresar</a>
                    <a *ngIf="logueado != null" class="s-name" style="cursor: pointer;" id="cerrarSesion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ logueado.nombre }}</a>
                    <div *ngIf="logueado != null" class="dropdown-menu session" aria-labelledby="cerrarSesion">
                        <a class="dropdown-item" href="/change-info">Mi cuenta</a>
                        <a class="dropdown-item" href="/purchase">Mis compras</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Salir</a>
                    </div>

                    <div class="info">
                        <div class="dropdown">
                            <button class="btn btn-primary" type="button" data-toggle="collapse"
                            data-target="#collapseDesktop" aria-expanded="false"
                            aria-controls="collapseDesktop">
                            <span class="lnr lnr-menu"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>

<header class="container-fluid d-block d-md-none">
    <div class="container">
        <nav class="nav-mobile">
            <div class="row w-100">

                <div class="col-3 logo">
                    <h1><a href="#"><img class="img-fluid" src="/assets/images/Logo-ER-m.png" alt=""></a></h1>
                </div>

                <div class="col-7 d-flex flex-row align-items-center">
                    <form class="form-inline ">
                        <div class="input-group">
                            <span class="input-group-text"><img src="/assets/images/Icons-15.svg" alt="icono" class="nav-svg">
                            </span>
                            <input class="form-control" type="search" placeholder="¿Qué producto buscas?" aria-label="Search"  (input)="buscar($event)" (keypress)="traerResultados($event, null)">
                        </div>
                        <div *ngIf="resultados != null" class="dropdown-menu m-searcher d-block">
                            <a *ngFor="let producto of resultados" class="dropdown-item" style="cursor: pointer;" (click)="traerResultados(null, producto.id)">{{ producto.nombre }}</a>
                            <a *ngIf="resultados.length == 0" class="dropdown-item" style="cursor: default;" disabled>NO HAY RESULTADOS</a>
                        </div>
                    </form>
                </div>

                <div class="col-2 d-flex flex-row align-items-center line-icon">
                    <div class="info">
                        <button class="btn btn-primary" type="button" data-toggle="collapse"
                            data-target="#collapseMobileMenu" aria-expanded="false"
                            aria-controls="collapseMobileMenu">
                            <span class="lnr lnr-menu"></span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>

<div class="container menu-desktop">
    <div class="collapse products-col" id="collapseDesktop">
        <div class="card card-body">
            <p>Productos</p>
            <div class="dropdown-divider"></div>

            <a style="cursor: pointer;" *ngFor="let categoria of categorias" (click)="productosPorCategoria(categoria.id)">{{ categoria.nombre }}</a>

        </div>
    </div>
</div>

<div class="container menu-mobile">
    <div class="collapse m-products-col" id="collapseMobileMenu">
        <div class="card card-body">
            <a class="dropdown-item cat" data-toggle="collapse" href="#collapseCategoria" aria-expanded="false"
                aria-controls="collapseExample">
                Categorías
            </a>
            <div class="collapse collapse-item" id="collapseCategoria">
                <div class="card card-body sub-col-p">
                    <a style="cursor: pointer;" *ngFor="let categoria of categorias" (click)="productosPorCategoria(categoria.id)">{{ categoria.nombre }}</a>
                </div>
            </div>
            <div class="dropdown-divider"></div>
            <a *ngIf="logueado == null" class="dropdown-item enter" href="/login">Ingresar</a>
            <a *ngIf="logueado != null" class="dropdown-item enter" href="/change-info">Mi cuenta</a>
            <a *ngIf="logueado != null" class="dropdown-item enter" href="/purchase">Mis compras</a>
            <a *ngIf="logueado != null" class="dropdown-item enter" (click)="logout()" style="cursor: pointer;">Salir</a>
        </div>
    </div>
</div>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlertaHeader">
</button>

<div class="modal fade" id="modalAlertaHeader" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>
