import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VentaServicio = /** @class */ (function () {
    function VentaServicio(http) {
        this.http = http;
        this.server = environment.baseUrl + '/api/venta';
    }
    VentaServicio.prototype.crearVenta = function (venta) {
        return this.http.post(this.server, venta);
    };
    VentaServicio.prototype.crearDetalle = function (detalle, id) {
        var p = {
            idCliente: id
        };
        return this.http.post(this.server + '/detalle', detalle, { params: p });
    };
    //pagoEnEfectivo(id: string, observaciones: string, montoEnvio: string, direccion: string, horario: string, latitud: any, longitud: any, descuentoId: string, listaIds: any[]): Observable<any> {
    VentaServicio.prototype.pagoEnEfectivo = function (id, observaciones, montoEnvio, direccion, horario, latitud, longitud, descuentoId) {
        var p = {
            clienteId: id,
        };
        //let p2 = {
        //	idsProductos: listaIds
        //};
        if (observaciones != null) {
            p['observaciones'] = observaciones;
        }
        if (montoEnvio != null) {
            p['montoEnvio'] = montoEnvio;
        }
        if (direccion != null) {
            p['direccion'] = direccion;
        }
        if (horario != null) {
            p['horario'] = horario;
        }
        if (latitud != null) {
            p['latitud'] = latitud;
        }
        if (longitud != null) {
            p['longitud'] = longitud;
        }
        if (longitud != null) {
            p['descuentoId'] = descuentoId;
        }
        //return this.http.post<any>(this.server + '/pagoEfectivo', null, { params: { ...p, ...p2 } });
        return this.http.post(this.server + '/pagoEfectivo', null, { params: tslib_1.__assign({}, p) });
    };
    VentaServicio.prototype.procesarPagoEfectivo = function (ventaId) {
        var p = {
            ventaId: ventaId
        };
        return this.http.post(this.server + '/procesarPagoEfectivo', null, { params: p });
    };
    VentaServicio.prototype.eliminarDetalle = function (id, idCliente) {
        var p = {
            idCliente: idCliente
        };
        return this.http.delete(this.server + '/detalle/' + id, { params: p });
    };
    VentaServicio.prototype.ventasPorCliente = function (id, desde, hasta) {
        var p = {
            desde: desde,
            hasta: hasta
        };
        return this.http.get(this.server + '/cliente/' + id, { params: p });
    };
    VentaServicio.prototype.getCarrito = function (id) {
        var p = {
            idCliente: id
        };
        return this.http.get(this.server + '/carrito', { params: p });
    };
    VentaServicio.prototype.vaciarCarrito = function (id) {
        var p = {
            idCliente: id
        };
        return this.http.post(this.server + '/vaciar-carrito', null, { params: p });
    };
    VentaServicio.prototype.agregarObservacion = function (detalleId, observacion) {
        var p = {
            observacion: observacion
        };
        return this.http.post(this.server + '/observacion/' + detalleId, null, { params: p });
    };
    VentaServicio.prototype.cambiarCantidad = function (cantidad, id, idCliente) {
        var p = {
            cantidad: cantidad,
            idCliente: idCliente
        };
        return this.http.post(this.server + '/cantidad/' + id, null, { params: p });
    };
    VentaServicio.prototype.recuperarCarrito = function (carrito, idCliente) {
        var p = {
            idCliente: idCliente
        };
        return this.http.post(this.server + '/recuperar-carrito', carrito, { params: p });
    };
    VentaServicio.ngInjectableDef = i0.defineInjectable({ factory: function VentaServicio_Factory() { return new VentaServicio(i0.inject(i1.HttpClient)); }, token: VentaServicio, providedIn: "root" });
    return VentaServicio;
}());
export { VentaServicio };
