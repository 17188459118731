<app-header></app-header>

<section class="title-ch">
  <div class="container">
      <div class="row">
          <h5>CONTACTO</h5>
          <div class="colorbar"><span></span></div>
          <p>¡Bienvenido al sitio de soporte de Papelería Entre Ríos!</p>
          <p>Por favor complete el siguiente formulario con su inquietud y estaremos contestando a la brevedad.</p>
      </div>
  </div>
</section>

<section class="ch-information">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
              <form [formGroup]="supportForm" (ngSubmit)="enviarMensaje()">
                  <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input type="text" class="form-control" formControlName="nombre" aria-describedby="emailHelp">
                  </div>
                  <div class="form-group">
                      <label for="correo">Dirección de email</label>
                      <input type="email" class="form-control" formControlName="mail">
                  </div>
                  <div class="form-group">
                      <label for="empresa">Deje aquí su mensaje</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="mensaje"></textarea>
                  </div>
                  <button type="submit" class="btn btn-primary">Enviar</button>
              </form>
          </div>
      </div>
  </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
    aria-hidden="true">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body text-center">
        {{ mensaje }}
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

        </div>
    </div>
    </div>
</div>

<app-footer></app-footer>