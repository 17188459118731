import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DetalleVenta } from 'src/app/entidades/detalle-venta';
import { ProductoServicio } from 'src/app/servicios/producto.servicio';
import { Cliente } from 'src/app/entidades/cliente';
import { Pago } from 'src/app/entidades/pago';
import { Venta } from 'src/app/entidades/venta';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss']
})
export class PurchaseDetailComponent implements OnInit {

  detalles: DetalleVenta[] = [];

  logueado: Cliente;

  subTotal: string = null;

  gastosEntrega: string = null;

  total: string = null;

  descuento: string = null;

  pago: Pago;

  venta: Venta;

  constructor(private router: Router, private productoServicio: ProductoServicio) { }

  ngOnInit() {
    this.obtenerLogueado();
    this.obtenerDetalles();
  }

  obtenerDetalles() {
    if (localStorage.getItem('venta') != null) {
      this.pago = JSON.parse(localStorage.getItem('venta')).pago;
      this.venta = JSON.parse(localStorage.getItem('venta'));
      this.detalles = JSON.parse(localStorage.getItem('venta')).detalleVenta;
      this.detalles.forEach(d => {
        d.monto = this.ordenarDecimales(d.monto);
        d.producto.precio1 = this.ordenarDecimales(d.producto.precio1);
        d.producto.precio2 = this.ordenarDecimales(d.producto.precio2);
        d.producto.precio3 = this.ordenarDecimales(d.producto.precio3);
        d.producto.precio4 = this.ordenarDecimales(d.producto.precio4);
      })
      let sub = JSON.parse(localStorage.getItem('venta')).monto - JSON.parse(localStorage.getItem('venta')).envio;
      this.subTotal = this.ordenarDecimales('' + sub)
      if (JSON.parse(localStorage.getItem('venta')).envio != null) {
        this.gastosEntrega = this.ordenarDecimales(JSON.parse(localStorage.getItem('venta')).envio);
      }
      this.obtenerTotal();
    } else {
      this.router.navigate(['/purchase']);
    }
  }

  obtenerLogueado() {
    this.logueado = JSON.parse(localStorage.getItem('logueado'));
    if (this.logueado == null) {
      this.router.navigate(['/']);
    }
  }

  pathImagen(path: string) {
    return this.productoServicio.getImagen(path);
  }

  ordenarDecimales(valor) {
    let numero;
    if (Number(valor) % 1 != 0) {
      let decimales: string = valor.toString().substring(valor.toString().indexOf('.') + 1);
      if (decimales.length == 1) {
        decimales = decimales + '0';
      } else if (decimales.length >= 2) {
        decimales = decimales.substring(0, 2);
      }
      numero = valor.toString().substring(0, valor.toString().indexOf('.')) + '.' + decimales;
    } else {
      numero = valor + '.00';
    }
    return numero;
  }

  obtenerTotal() {
    let sub = Number(this.subTotal);
    let gastos = Number(this.gastosEntrega);
    let desc = Number(this.descuento);

    let t;
    t = sub + gastos - desc;
    
    this.total = this.ordenarDecimales(t);
  }

}
