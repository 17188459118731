<app-header></app-header>

<section class="title-ch">
    <div class="container">
        <div class="row">
            <h5>CAMBIAR DATOS DE MI CUENTA</h5>
            <div class="colorbar"><span></span></div>
        </div>
    </div>
</section>

<section class="ch-information">
    <div class="container">
        <form [formGroup]="infoForm" (ngSubmit)="modificarDatos()">
            <div class="row" *ngIf="logueado.identificador != null">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="nombre">Nombre de contacto</label>
                        <input formControlName="nombre" type="text" class="form-control" placeholder="Juan Pérez">
                    </div>
                    <div class="form-group">
                        <label for="telefono">Número de teléfono de contacto</label>
                        <input formControlName="telefono" type="number" class="form-control" placeholder="26152558554">
                    </div>
                    <div class="form-group">
                        <label>Nombre empresa</label>
                        <input formControlName="nombreEmpresa" type="text" class="form-control" placeholder="Ingrese nombre de la empresa">
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Cuenta de email</label>
                        <input formControlName="email" type="email" class="form-control" placeholder="Ingrese su cuenta de email">
                    </div>
                    <div class="form-group">
                        <label>Identificador</label>
                        <input formControlName="identificador" type="number" class="form-control" placeholder="Ingrese número de CUIT de la empresa">
                    </div>
                    <div class="form-group">
                        <label>Nombre de usuario</label>
                        <input formControlName="nombreUsuario" type="text" class="form-control" placeholder="Ingrese nombre de la empresa" disabled>
                    </div>
                    <button type="submit" class="btn btn-primary">Guardar cambios</button>
                    <a href="/change-pass">Modificar contraseña</a>
                </div>
            </div>

            <div class="row" *ngIf="logueado.identificador == null">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="nombre">Nombre</label>
                        <input formControlName="nombre" type="text" class="form-control" placeholder="Juan Pérez">
                    </div>
                    <div class="form-group">
                        <label for="usuario">Apellido</label>
                        <input formControlName="apellido" type="text" class="form-control" placeholder="JuanPérez22">
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Cuenta de email</label>
                        <input formControlName="email" type="email" class="form-control" placeholder="Ingrese su cuenta de email">
                    </div>
                    <div class="form-group">
                        <label for="telefono">Número de teléfono</label>
                        <input formControlName="telefono" type="number" class="form-control" placeholder="26152558554">
                    </div>
                    <div class="form-group">
                        <label>Nombre de usuario</label>
                        <input formControlName="nombreUsuario" type="text" class="form-control" placeholder="Ingrese nombre de la empresa" disabled>
                    </div>
                    <button type="submit" class="btn btn-primary">Guardar cambios</button>
                    <a href="/change-pass">Modificar contraseña</a>
                </div>
            </div>
    </form>
    </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>