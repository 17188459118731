import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Cliente } from 'src/app/entidades/cliente';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';

@Component({
  selector: 'app-change-info',
  templateUrl: './change-info.component.html',
  styleUrls: ['./change-info.component.scss']
})
export class ChangeInfoComponent implements OnInit {

  infoForm: FormGroup;

  logueado: Cliente;

  @ViewChild("botonAlerta") botonAlerta: ElementRef;

  mensaje: string = null;

  loginForm: FormGroup;
  
  constructor(private router: Router, private clienteServicio: ClienteServicio) { }

  ngOnInit() {
    if (localStorage.getItem('logueado') ==  null) {
      this.router.navigate(['/']);
    } else {
      this.logueado = JSON.parse(localStorage.getItem('logueado'));
    }
    this.inicializarForm();
  }

  inicializarForm() {
    this.infoForm = new FormGroup({
      'nombre': new FormControl("", [
        Validators.required
      ]),
      'apellido': new FormControl("", [
        Validators.required
      ]),
      'telefono': new FormControl("", [
        Validators.required
      ]),
      'email': new FormControl("", [
        Validators.required
      ]),
      'nombreUsuario': new FormControl("", [
        Validators.required
      ]),
      'nombreEmpresa': new FormControl("", [
        Validators.required
      ]),
      'identificador': new FormControl("", [
        Validators.required
      ])
    })

    this.infoForm.patchValue({ nombre: this.logueado.nombre, apellido: this.logueado.apellido, telefono: this.logueado.telefono, 
      email: this.logueado.email, nombreEmpresa: this.logueado.nombreEmpresa, identificador: this.logueado.identificador,
      nombreUsuario: this.logueado.nombreUsuario });
  }

  modificarDatos() {
    let cliente: Cliente;
    if (this.logueado.identificador != null) {
      if (this.infoForm.get('nombre').value != '' && this.infoForm.get('email').value != ''
        && this.infoForm.get('identificador').value != '' && this.infoForm.get('telefono').value != ''
        && this.infoForm.get('nombreEmpresa').value != '' && this.infoForm.get('apellido').value != '') {
          if (this.validarEmail(this.infoForm.get('email').value)) {
            cliente = {
              id: this.logueado.id,
              nombre: this.infoForm.get('nombre').value,
              apellido: this.infoForm.get('apellido').value,
              email: this.infoForm.get('email').value,
              eliminado: null,
              fechaAlta: null,
              fechaBaja: null,
              modificacion: null,
              identificador: this.infoForm.get('identificador').value,
              lista: null,
              clave: null,
              libertya: null,
              ventas: null,
              direccion: null,
              telefono: this.infoForm.get('telefono').value,
              nombreEmpresa: this.infoForm.get('nombreEmpresa').value,
              nombreUsuario: null
            }
            this.clienteServicio.modificar(cliente).subscribe(res => {
              localStorage.setItem('logueado', JSON.stringify(res));
              this.mensaje = "Datos modificados correctamente.";
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            }, error => {
              this.mensaje = "Error al modificar sus datos. Intente nuevamente más tarde.";
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            })
          } else {
            this.mensaje = "Ingrese una cuenta de email válida.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          }
      } else {
        this.mensaje = "Complete todos los campos.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    } else {
      if (this.infoForm.get('nombre').value != '' && this.infoForm.get('email').value != ''
        && this.infoForm.get('telefono').value != '' && this.infoForm.get('apellido').value != '') {
          if (this.validarEmail(this.infoForm.get('email').value)) {
            cliente = {
              id: this.logueado.id,
              nombre: this.infoForm.get('nombre').value,
              apellido: this.infoForm.get('apellido').value,
              email: this.infoForm.get('email').value,
              eliminado: null,
              fechaAlta: null,
              fechaBaja: null,
              modificacion: null,
              identificador: null,
              lista: null,
              clave: null,
              libertya: null,
              ventas: null,
              direccion: null,
              telefono: this.infoForm.get('telefono').value,
              nombreEmpresa: null,
              nombreUsuario: null
            }
            this.clienteServicio.modificar(cliente).subscribe(res => {
              localStorage.setItem('logueado', JSON.stringify(res));
              this.mensaje = "Datos modificados correctamente.";
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            }, error => {
              this.mensaje = error['error'].message;
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            })
          } else {
            this.mensaje = "Ingrese una cuenta de email válida.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          }          
      } else {
        this.mensaje = "Complete todos los campos.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    }
  }

  validarEmail(email) {
    let r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return r.test(email);
  }

}
