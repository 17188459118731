import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Descuento } from '../entidades/descuento';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DescuentoServicio {

    public server: string = environment.baseUrl + '/api/descuento';

    constructor(private http: HttpClient) {}

    getDescuento(codigo: string, idCliente: string): Observable<Descuento> {
        let p = {
            codigo: codigo,
            idCliente: idCliente
        }
        return this.http.get<Descuento>(this.server, { params: p });
    }

}