import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { CheckOutComponent } from './componentes/check-out/check-out.component';
import { HeaderComponent } from './componentes/header/header.component';
import { LoginComponent } from './componentes/login/login.component';
import { RestorePassComponent } from './componentes/restore-pass/restore-pass.component';
import { SearchComponent } from './componentes/search/search.component';
import { SingleProductComponent } from './componentes/single-product/single-product.component';
import { RegistryComponent } from './componentes/registry/registry.component';
import { FaqComponent } from './componentes/faq/faq.component';
import { ChangeInfoComponent } from './componentes/change-info/change-info.component';
import { PrivacyComponent } from './componentes/privacy/privacy.component';
import { SupportComponent } from './componentes/support/support.component';
import { PurchaseComponent } from './componentes/purchase/purchase.component';
import { PurchaseDetailComponent } from './componentes/purchase-detail/purchase-detail.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { ChangePassComponent } from './componentes/change-pass/change-pass.component';
import { IndexComponent } from './componentes/index/index.component';
import { ErrorComponent } from './componentes/error/error.component';
import { NoResultsComponent } from './componentes/no-results/no-results.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CheckOutComponent,
    HeaderComponent,
    LoginComponent,
    RestorePassComponent,
    SearchComponent,
    SingleProductComponent,
    RegistryComponent,
    FaqComponent,
    ChangeInfoComponent,
    ChangePassComponent,
    PrivacyComponent,
    SupportComponent,
    PurchaseComponent,
    PurchaseDetailComponent,
    IndexComponent,
    ErrorComponent,
    NoResultsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA2X-XeCgdI9jQtMyFKoAy-dsvWo9fq2gY',
      libraries: ['places'] 
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
