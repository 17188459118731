import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  supportForm: FormGroup;

  @ViewChild("botonAlerta") botonAlerta: ElementRef;
  mensaje: string;
  
  constructor(private clienteServicio: ClienteServicio) { }

  ngOnInit() {
    this.inicializarFormulario();
  }

  inicializarFormulario() {
    this.supportForm = new FormGroup({
      'nombre': new FormControl("", [
        Validators.required
      ]),
      'mail': new FormControl("", [
        Validators.required
      ]),
      'mensaje': new FormControl("", [
        Validators.required
      ])
    })
  }

  enviarMensaje() {
    if (this.supportForm.get('nombre').value != '' && this.supportForm.get('mail').value != '' && this.supportForm.get('mensaje').value != '') {
      if (this.validarEmail(this.supportForm.get('mail').value)) {
        this.clienteServicio.enviarSupport(this.supportForm.get('nombre').value, this.supportForm.get('mail').value, this.supportForm.get('mensaje').value).subscribe(res => {
          this.mensaje = "Su mensaje ha sido enviado a nuestro servicio de soporte técnico.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
          this.supportForm.patchValue({ nombre: null, mail: null, mensaje: null });
        }, error => {
          this.mensaje = "Hubo un error al intentar enviar el mensaje. Intente nuevamente más tarde.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        })
      } else {
        this.mensaje = "Ingrese una cuenta de mail válida.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }      
    } else {
      this.mensaje = "Complete todos los campos.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

  validarEmail(email) {
    let r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return r.test(email);
  }

}
