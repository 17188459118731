import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClienteServicio = /** @class */ (function () {
    function ClienteServicio(http) {
        this.http = http;
        this.server = environment.baseUrl + '/api/cliente';
    }
    ClienteServicio.prototype.registro = function (cliente) {
        return this.http.post(this.server, cliente);
    };
    ClienteServicio.prototype.modificar = function (cliente) {
        return this.http.post(this.server + '/modificar', cliente);
    };
    ClienteServicio.prototype.enviarSupport = function (nombre, mail, mensaje) {
        var p = {
            nombre: nombre,
            mail: mail,
            mensaje: mensaje
        };
        return this.http.post(this.server + '/support', null, { params: p });
    };
    ClienteServicio.prototype.agregarDireccion = function (direccion, idCliente) {
        var p = {
            direccion: direccion
        };
        return this.http.post(this.server + '/direccion/' + idCliente, null, { params: p });
    };
    ClienteServicio.prototype.login = function (usuario, clave) {
        var p = {
            usuario: usuario,
            clave: clave
        };
        return this.http.post(this.server + '/login', null, { params: p });
    };
    ClienteServicio.prototype.modificarClave = function (email, actual, nueva) {
        var p = {
            email: email,
            actual: actual,
            nueva: nueva
        };
        return this.http.post(this.server + '/modificar-clave', null, { params: p });
    };
    ClienteServicio.prototype.buscarPorEmail = function (email) {
        var p = {
            email: email
        };
        return this.http.get(this.server + '/email', { params: p });
    };
    ClienteServicio.prototype.buscarDireccion = function (idCliente) {
        return this.http.get(this.server + '/direccion/' + idCliente);
    };
    ClienteServicio.prototype.validarCuenta = function (token) {
        var p = {
            token: token
        };
        return this.http.post(this.server + '/validar', null, { params: p });
    };
    ClienteServicio.prototype.restablecerClave = function (email) {
        var p = {
            email: email
        };
        return this.http.post(this.server + '/restablecer-clave', null, { params: p });
    };
    ClienteServicio.prototype.recuperarCuenta = function (token) {
        var p = {
            token: token
        };
        return this.http.post(this.server + '/recuperar-cuenta', null, { params: p });
    };
    ClienteServicio.ngInjectableDef = i0.defineInjectable({ factory: function ClienteServicio_Factory() { return new ClienteServicio(i0.inject(i1.HttpClient)); }, token: ClienteServicio, providedIn: "root" });
    return ClienteServicio;
}());
export { ClienteServicio };
