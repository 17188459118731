import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';
import { Cliente } from 'src/app/entidades/cliente';
import { catchError } from 'rxjs/operators'

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {

  claveForm: FormGroup;
  logueado: Cliente;

  @ViewChild("botonAlerta") botonAlerta: ElementRef;

  mensaje: string = null;

  loginForm: FormGroup;
  
  constructor(private router: Router, private clienteServicio: ClienteServicio) { }

  ngOnInit() {
    if (localStorage.getItem('logueado') ==  null) {
      this.router.navigate(['/']);
    }
    this.logueado = JSON.parse(localStorage.getItem('logueado'));
    this.inicializarForm();
  }

  inicializarForm() {
    this.claveForm = new FormGroup({
      'actual': new FormControl("", [
        Validators.required
      ]),
      'nueva': new FormControl("", [
        Validators.required
      ]),
      'nuevaR': new FormControl("", [
        Validators.required
      ])
    })
  }

  modificarClave() {
    if (this.claveForm.get('actual').value != '' && this.claveForm.get('nueva').value != ''
      && this.claveForm.get('nuevaR').value != '') {
        if (this.claveForm.get('nueva').value == this.claveForm.get('nuevaR').value) {
          if (this.claveForm.get('nueva').value.length < 8) {
            this.mensaje = "La contraseña debe tener mínimo 8 dígitos.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          } else {
            this.clienteServicio.modificarClave(this.logueado.email, this.claveForm.get('actual').value, this.claveForm.get('nueva').value).subscribe(res => {
              this.mensaje = "Contraseña modificada correctamente.";
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
              this.claveForm.patchValue({ actual: null, nueva: null, nuevaR: null });
            }, error => {
              this.mensaje = error['error'].message;
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            });
          }
        } else {
          this.mensaje = "Las contraseñas no coinciden.";
          let alert: HTMLElement = this.botonAlerta.nativeElement;
          alert.click();
        }
    } else {
      this.mensaje = "Complete todos los campos.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

}
