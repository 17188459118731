import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Departamento } from '../entidades/departamento';

@Injectable({
    providedIn: 'root'
})
export class DepartamentoServicio {

    public server: string = environment.baseUrl + '/api/departamentos';

    constructor(private http: HttpClient) {}

    listarDepartamentos(): Observable<Array<Departamento>> {
        return this.http.get<Array<Departamento>>(this.server);
    }

}