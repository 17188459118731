import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Venta } from '../entidades/venta';
import { DetalleVenta } from '../entidades/detalle-venta';

@Injectable({
	providedIn: 'root'
})
export class VentaServicio {

	public server: string = environment.baseUrl + '/api/venta';

	constructor(private http: HttpClient) { }

	crearVenta(venta: Venta): Observable<any> {
		return this.http.post<any>(this.server, venta);
	}

	crearDetalle(detalle: DetalleVenta, id: string): Observable<any> {
		let p = {
			idCliente: id
		}
		return this.http.post<any>(this.server + '/detalle', detalle, { params: p });
	}


	//pagoEnEfectivo(id: string, observaciones: string, montoEnvio: string, direccion: string, horario: string, latitud: any, longitud: any, descuentoId: string, listaIds: any[]): Observable<any> {
	pagoEnEfectivo(id: string, observaciones: string, montoEnvio: string, direccion: string, horario: string, latitud: any, longitud: any, descuentoId: string): Observable<any> {
		let p = {
			clienteId: id,

		};
		//let p2 = {
		//	idsProductos: listaIds
		//};
		if (observaciones != null) {
			p['observaciones'] = observaciones;
		}
		if (montoEnvio != null) {
			p['montoEnvio'] = montoEnvio;
		}
		if (direccion != null) {
			p['direccion'] = direccion;
		}
		if (horario != null) {
			p['horario'] = horario;
		}
		if (latitud != null) {
			p['latitud'] = latitud;
		}
		if (longitud != null) {
			p['longitud'] = longitud;
		}
		if (longitud != null) {
			p['descuentoId'] = descuentoId;
		}
		//return this.http.post<any>(this.server + '/pagoEfectivo', null, { params: { ...p, ...p2 } });
		return this.http.post<any>(this.server + '/pagoEfectivo', null, { params: { ...p } });
	}

	procesarPagoEfectivo(ventaId: string) {
		let p = {
			ventaId: ventaId
		}
		return this.http.post<string>(this.server + '/procesarPagoEfectivo', null, { params: p });
	}

	eliminarDetalle(id: string, idCliente): Observable<Response> {
		let p = {
			idCliente: idCliente
		}
		return this.http.delete<Response>(this.server + '/detalle/' + id, { params: p });
	}

	ventasPorCliente(id: string, desde: string, hasta: string): Observable<Array<Venta>> {
		let p = {
			desde: desde,
			hasta: hasta
		}
		return this.http.get<Array<Venta>>(this.server + '/cliente/' + id, { params: p });
	}

	getCarrito(id: string): Observable<Venta> {
		let p = {
			idCliente: id
		}
		return this.http.get<Venta>(this.server + '/carrito', { params: p });
	}

	vaciarCarrito(id: string): Observable<Response> {
		let p = {
			idCliente: id
		}
		return this.http.post<Response>(this.server + '/vaciar-carrito', null, { params: p });
	}

	agregarObservacion(detalleId: string, observacion: string): Observable<any> {
		let p = {
			observacion: observacion
		}
		return this.http.post<any>(this.server + '/observacion/' + detalleId, null, { params: p });
	}

	cambiarCantidad(cantidad: string, id: string, idCliente: string): Observable<DetalleVenta> {
		let p = {
			cantidad: cantidad,
			idCliente: idCliente
		}
		return this.http.post<any>(this.server + '/cantidad/' + id, null, { params: p });
	}

	recuperarCarrito(carrito: any[], idCliente: string): Observable<Venta> {
		let p = {
			idCliente: idCliente
		}
		return this.http.post<Venta>(this.server + '/recuperar-carrito', carrito, { params: p });
	}

}
