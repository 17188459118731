<app-header></app-header>

<section class="forms">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="gray">
          <h5>RECUPERAR CONTRASEÑA</h5>
          <div class="colorbar"><span></span></div>
          <p>Ingresá tu mail y recibirás un correo con un link para restaurar tu constraseña</p>
          <form>
            <div class="form-group">
              <input #inputEmail type="email" class="form-control" placeholder="Ingrese su mail" aria-describedby="emailHelp">
            </div>
            <button type="submit" class="btn btn-primary" (click)="restablecerClave()">Recuperar contraseña</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>