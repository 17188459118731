<app-header></app-header>

<div class="container cont-error">
  <div class="rows">
    <div>
      <h4>Uups! Búsqueda sin resultados.</h4>
    </div>
    <div class="img-error">
      <img class="img-fluid" src="/assets/images/no-results-found.jpg" alt="">
    </div>
  </div>
</div>

<app-footer></app-footer>