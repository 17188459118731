<app-header></app-header>

<section class="m-chart d-block d-md-none">
	<div class="container">
		<button type="button" class="btn chart-button" data-toggle="collapse"
			data-target="#collapseChart" aria-expanded="false"
			aria-controls="collapseExample">

			<img class="img-fluid" src="./assets/images/supermarket.svg"
				alt="chart"> <span class="badge badge-light">{{
				carrito.length }}</span>
		</button>
		<div class="collapse" id="collapseChart">
			<div class="card card-body">
				<div class="container">
					<div class="chart-p" *ngFor="let detalle of carrito; index as i">
						<div class="row c-chart">
							<a style="cursor: pointer;"
								(click)="abrirModalEliminar(detalle, i)"><span
								class="lnr lnr-cross"></span></a>
						</div>
						<div class="row c-info-p">
							<div class="col-3 col-lg-3 c-info-img">
								<img class="img-fluid"
									*ngIf="detalle.producto?.foto != null && detalle.producto?.foto != ''"
									[src]="pathImagen(detalle.producto?.foto)" alt="{{ detalle.producto?.amigable }}"onerror="this.src='/assets/images/Logo-ER(3).png'">
								<img class="img-fluid"
									*ngIf="detalle.producto?.foto == null || detalle.producto?.foto == ''"
									src="/assets/images/Logo-ER(3).png" alt="{{ detalle.producto?.amigable }}">
							</div>
							<div class="col-5 col-lg-6">
								<h6 style="-webkit-box-orient: vertical;">{{
									detalle.producto?.nombre }}</h6>
								<span>x<span>{{ detalle.cantidad }}</span></span>
							</div>
							<div class="col-4 col-lg-3">
								<p>
									$<span>{{ detalle.monto.toString().substring(0,
										detalle.monto.toString().indexOf('.')) }},<sup>{{
											detalle.monto.toString().substring(detalle.monto.toString().indexOf('.')
											+ 1) }}</sup>
									</span>
								</p>
							</div>
						</div>
						<div class="row p-character">
							<div class="form-row align-items-center">
								<div class="col-10">
									<input type="text" class="form-control"
										placeholder="Color|Tamaño|Medida|Otras"
										[(ngModel)]="detalle.observacion">
								</div>
								<div class="col-2">
									<button type="submit" class="btn"
										(click)="agregarObservacion(detalle)">
										<span class="lnr lnr-pencil"></span>
									</button>
								</div>
							</div>
						</div>
						<div class="g-bar"></div>
					</div>
					<div class="row total" *ngIf="carrito.length > 0">
						<div class="col-4">
							Total: $<span> {{ totalCarrito }}</span>
						</div>
						<div class="col-5">
							<a href="/checkout" *ngIf="logueado != null">Finalizar compra</a>
							<a href="/login" *ngIf="logueado == null">Finalizar compra</a>
						</div>
						<div class="col-3">
							<a style="cursor: pointer;" (click)="abrirModalVaciar()"><span
								class="lnr lnr-trash"></span>Vaciar</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>

<section class="title-filter">
	<div class="container">
		<div class="row">
			<h6>Resultados de búsqueda</h6>
			<div class="colorbar">
				<span></span>
			</div>
		</div>
	</div>

</section>

<section class="products">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-8">
				<div class="search-r" *ngIf="productoDestacado != null">
					<div class="row">
						<div class="col-3 d-none d-md-block">
							<div class="img-product">
								<a href="/producto/{{ productoDestacado.amigable }}"> <img
									class="img-fluid"
									*ngIf="productoDestacado.foto != null && productoDestacado.foto != ''"
									[src]="pathImagen(productoDestacado.foto)" alt="{{ productoDestacado?.amigable }}"onerror="this.src='/assets/images/Logo-ER(3).png'">
									<img class="img-fluid"
									*ngIf="productoDestacado.foto == null || productoDestacado.foto == ''"
									src="/assets/images/Logo-ER(3).png" alt="{{ productoDestacado.producto?.amigable }}">
								</a>
							</div>
						</div>
						<div class="col-9 d-none d-md-block">
							<div class="name">
								<a href="/producto/{{ productoDestacado.amigable }}">{{
									productoDestacado.nombre }}</a>
							</div>
							<div class="descrip">
								<p>{{ productoDestacado.descripcion }}</p>
							</div>
              <div class="price" *ngIf="productoDestacado.precioOferta != null">
                <p>
                  $<span>{{
										productoDestacado.precioOferta.toString().substring(0,
										productoDestacado.precioOferta.toString().indexOf('.')) }}</span>
                  <sup>{{
                    productoDestacado.precioOferta.toString().substring(productoDestacado.precioOferta.toString().indexOf('.')
                    + 1) }}</sup>
                </p>
              </div>
							<div class="price" *ngIf="productoDestacado.precioOferta == null && logueado == null">
								<p>
									$<span>{{
										productoDestacado.precio2.toString().substring(0,
										productoDestacado.precio2.toString().indexOf('.')) }}</span>
                  <sup>{{
										productoDestacado.precio2.toString().substring(productoDestacado.precio2.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 1">
								<p>
									$<span>{{
										productoDestacado.precio1.toString().substring(0,
										productoDestacado.precio1.toString().indexOf('.')) }}</span>
                  <sup>{{
										productoDestacado.precio1.toString().substring(productoDestacado.precio1.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 2">
								<p>
									$<span>{{
										productoDestacado.precio2.toString().substring(0,
										productoDestacado.precio2.toString().indexOf('.')) }}</span>
                  <sup>{{
										productoDestacado.precio2.toString().substring(productoDestacado.precio2.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 3">
								<p>
									$<span>{{
										productoDestacado.precio3.toString().substring(0,
										productoDestacado.precio3.toString().indexOf('.')) }}</span>
                  <sup>{{
										productoDestacado.precio3.toString().substring(productoDestacado.precio3.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="logueado != null && logueado.lista == 4">
								<p>
									$<span>{{
										productoDestacado.precio4.toString().substring(0,
										productoDestacado.precio4.toString().indexOf('.')) }}</span>
                  <sup>{{
										productoDestacado.precio4.toString().substring(productoDestacado.precio4.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>

							<div
								*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
							</div>

							<ng-template #productoConStock>
								<div class="button-buy">
									<input type="number" class="form-control" id="inputCant"
										placeholder="0" [(ngModel)]="productoDestacado.cantidad">
									<a (click)="agregarCarrito(productoDestacado)"
										style="cursor: pointer;"><span class="lnr lnr-cart"></span>Comprar</a>
								</div>
							</ng-template>

							<ng-template #productoSinStock>
								<p class="btn-agotado">AGOTADO!</p>
							</ng-template>
						</div>
					</div>
					<div class="row d-block d-md-none">
						<div class="img-m">
							<a href="/producto/{{ productoDestacado.amigable }}"> <img
								class="img-fluid"
								*ngIf="productoDestacado.foto != null && productoDestacado.foto != ''"
								[src]="pathImagen(productoDestacado.foto)" alt="{{ productoDestacado?.amigable }}"onerror="this.src='/assets/images/Logo-ER(3).png'">
								<img class="img-fluid"
								*ngIf="productoDestacado.foto == null || productoDestacado.foto == ''"
								src="/assets/images/Logo-ER(3).png" alt="{{ productoDestacado?.amigable }}">
							</a>
						</div>

						<div class="info-m">
							<div class="name">
								<a href="/producto/{{ productoDestacado.amigable }}">{{
									productoDestacado.nombre }}</a>
							</div>
							<div class="descrip">
								<p>{{ productoDestacado.descripcion }}</p>
							</div>
              <div class="price" *ngIf="productoDestacado.precioOferta != null">
                <p>
                  $<span>{{
										productoDestacado.precioOferta.toString().substring(0,
										productoDestacado.precioOferta.toString().indexOf('.')) }}</span>
                  <sup>{{
                    productoDestacado.precioOferta.toString().substring(productoDestacado.precioOferta.toString().indexOf('.')
                    + 1) }}</sup>
                </p>
              </div>
							<div class="price" *ngIf="productoDestacado.precioOferta == null && logueado == null">
								<p>
									$<span>{{
										productoDestacado.precio2.toString().substring(0,
										productoDestacado.precio2.toString().indexOf('.')) }}</span><sup>{{
										productoDestacado.precio2.toString().substring(productoDestacado.precio2.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 1">
								<p>
									$<span>{{
										productoDestacado.precio1.toString().substring(0,
										productoDestacado.precio1.toString().indexOf('.')) }}</span>
                  <sup>{{
										productoDestacado.precio1.toString().substring(productoDestacado.precio1.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 2">
								<p>
									$<span>{{
										productoDestacado.precio2.toString().substring(0,
										productoDestacado.precio2.toString().indexOf('.')) }}</span><sup>{{
										productoDestacado.precio2.toString().substring(productoDestacado.precio2.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 3">
								<p>
									$<span>{{
										productoDestacado.precio3.toString().substring(0,
										productoDestacado.precio3.toString().indexOf('.')) }}</span><sup>{{
										productoDestacado.precio3.toString().substring(productoDestacado.precio3.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>
							<div class="price"
								*ngIf="productoDestacado.precioOferta == null && logueado != null && logueado.lista == 4">
								<p>
									$<span>{{
										productoDestacado.precio4.toString().substring(0,
										productoDestacado.precio4.toString().indexOf('.')) }}</span><sup>{{
										productoDestacado.precio4.toString().substring(productoDestacado.precio4.toString().indexOf('.')
										+ 1) }}</sup>
								</p>
							</div>

							<div
								*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
							</div>

							<ng-template #productoConStock>
								<div class="button-buy">
									<input type="number" class="form-control" id="inputCant"
										placeholder="0" [(ngModel)]="productoDestacado.cantidad">
									<a (click)="agregarCarrito(productoDestacado)"
										style="cursor: pointer;"><span class="lnr lnr-cart"></span>Comprar</a>
								</div>
							</ng-template>

							<ng-template #productoSinStock>
							  <p class="btn-agotado">AGOTADO!</p>
							</ng-template>
						</div>
					</div>
				</div>
				<div class="p-item">
					<div class="row">
						<div class="col-8 offset-2 offset-md-0 col-md-3"
							*ngFor="let producto of productos">
              <div *ngIf="producto.precioOferta !== null" style="position: relative;">
                <div style="position: absolute; bottom: 8; right: -22; background-color: red; color: white; border-radius: 50%; padding: 5px 10px; font-size: 12px;">
                  ¡Oferta!
                </div>
              </div>
							<div class="img-product">
								<a href="/producto/{{ producto.amigable }}"> <img
									class="img-fluid"
									*ngIf="producto.foto != null && producto.foto != ''"
									[src]="pathImagen(producto.foto)" alt="{{ producto?.amigable }}"onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
									class="img-fluid"
									*ngIf="producto.foto == null || producto.foto == ''"
									src="/assets/images/Logo-ER(3).png" alt="{{ producto?.amigable }}">
								</a>
							</div>
							<p>{{ producto.codigo }}</p>
							<div class="row p-name-item">
								<div class="col-8">
									<a href="/producto/{{ producto.amigable }}"
										style="-webkit-box-orient: vertical;">{{ producto.nombre }}</a>
								</div>
              <div class="row">
                <div class="col-4" *ngIf="producto.precioOferta !=null">
                  <p>
                    $<span>{{ producto.precioOferta }}</span>

                  </p>
                </div>
								<div class="col-4" *ngIf="producto.precioOferta == null && logueado == null">
									<p>
										$<span>{{ producto?.precio2.toString().substring(0,producto?.precio2.toString().indexOf('.')) }}</span>
                    <sup>{{ producto?.precio2.toString().substring(producto?.precio2.toString().indexOf('.') + 1) }}</sup>
									</p>
								</div>
								<div class="col-4"
									*ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 1">
									<p>
										$<span>{{ producto?.precio1.toString().substring(0,producto?.precio1.toString().indexOf('.')) }}</span>
                    <sup>{{ producto?.precio1.toString().substring(producto?.precio1.toString().indexOf('.') + 1) }}</sup>
									</p>
								</div>
                <div class="col-4"
                     *ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 2">
                  <p>
                    $<span>{{ producto?.precio2.toString().substring(0,producto?.precio1.toString().indexOf('.')) }}</span>
                    <sup>{{ producto?.precio2.toString().substring(producto?.precio2.toString().indexOf('.') + 1) }}</sup>
                  </p>
                </div>
								<div class="col-4"
									*ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 3">
									<p>
										$<span>{{ producto?.precio3.toString().substring(0,producto?.precio3.toString().indexOf('.')) }}</span>
                    <sup>{{ producto?.precio3.toString().substring(producto?.precio3.toString().indexOf('.') + 1) }}</sup>
									</p>
								</div>
								<div class="col-4"
									*ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 4">
									<p>
										$<span>{{ producto?.precio4.toString().substring(0,
											producto?.precio4.toString().indexOf('.')) }}</span><sup>{{
											producto?.precio4.toString().substring(producto?.precio4.toString().indexOf('.')
											+ 1) }}</sup>
									</p>
								</div>
							</div>
              </div>
							<div class="row margin-row">
								<div class="col-5">
									<input type="number" class="form-control" id="inputCant"
										placeholder="1" min="1" [(ngModel)]="producto.cantidad">
								</div>
								<div class="col-7">
									<div
										*ngIf="producto?.disponible === true; then productoConStock else productoSinStock">
									</div>

									<ng-template #productoConStock>
										<a style="cursor: pointer;" (click)="agregarCarrito(producto)"><span
											class="lnr lnr-cart"></span>Comprar</a>
									</ng-template>

									<ng-template #productoSinStock>
									  <p class="btn-agotado">AGOTADO!</p>
									</ng-template>
								</div>
							</div>
						</div>
					</div>

					<nav aria-label="Page navigation example" *ngIf="totalPaginas > 1">
						<ul class="pagination">
							<li class="page-item"><a class="page-link"
								style="cursor: pointer;" (click)="anteriorPagina()"
								aria-label="Previous"> <span aria-hidden="true">&laquo;</span>
									<span class="sr-only">Anterior</span>
							</a></li>
							<li class="page-item" *ngFor="let key of arrayPaginas"
								[ngClass]="{ 'active': key - 1 == actualPagina }"><a
								class="page-link" style="cursor: pointer;"
								(click)="cambiarPagina(key)"
								[ngClass]="{ 'text-white': key - 1 == actualPagina }">{{ key
									}}</a></li>
							<li class="page-item"><a class="page-link"
								style="cursor: pointer;" (click)="siguientePagina()"
								aria-label="Next"> <span aria-hidden="true">&raquo;</span> <span
									class="sr-only">Siguiente</span>
							</a></li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="d-none d-md-block col-md-4">
				<div class="chart">
					<h6 *ngIf="carrito.length > 0">MI CARRITO</h6>
					<div class="colorbar" *ngIf="carrito.length > 0">
						<span></span>
					</div>
					<div class="scroll" *ngIf="carrito.length > 0">
						<div class="chart-p" *ngFor="let detalle of carrito; index as i">
							<div class="row c-chart">
								<a style="cursor: pointer;"
									(click)="abrirModalEliminar(detalle, i)"><span
									class="lnr lnr-cross"></span></a>
							</div>
							<div class="row">
								<div class="col-3 col-lg-3 img-p-ch">
									<img class="img-fluid"
										*ngIf="detalle.producto.foto != null && detalle.producto.foto != ''"
										[src]="pathImagen(detalle.producto.foto)" alt="{{ detalle.producto?.amigable }}"onerror="this.src='/assets/images/Logo-ER(3).png'">
									<img class="img-fluid"
										*ngIf="detalle.producto.foto == null || detalle.producto.foto == ''"
										src="/assets/images/Logo-ER(3).png" alt="{{ detalle.producto?.amigable }}">
								</div>
								<div class="col-5 col-lg-6">
									<h6 style="-webkit-box-orient: vertical;">{{
										detalle.producto.nombre }}</h6>
									<span>x<span>{{ detalle.cantidad }}</span></span>
								</div>
								<div class="col-4 col-lg-3">
									<p>
										$<span>{{ detalle.monto.toString().substring(0,
											detalle.monto.toString().indexOf('.')) }},<sup>{{
												detalle.monto.toString().substring(detalle.monto.toString().indexOf('.')
												+ 1) }}</sup>
										</span>
									</p>
								</div>
							</div>
							<div class="row p-character">
								<div class="form-row align-items-center">
									<div class="col-10">
										<input type="text" class="form-control"
											placeholder="Color|Tamaño|Medida|Otras"
											[(ngModel)]="detalle.observacion">
									</div>
									<div class="col-2">
										<button type="submit" class="btn"
											(click)="agregarObservacion(detalle)">
											<span class="lnr lnr-pencil"></span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="empty-c" *ngIf="carrito.length > 0">
						<button data-toggle="modal" data-target="#modalVaciar"
							class="btn ept" (click)="abrirModalVaciar()">
							<span class="lnr lnr-trash"></span>Vaciar Carrito
						</button>
					</div>
					<div class="t-chart" *ngIf="carrito.length > 0">
						<p>
							Total: <span>$<span>{{ totalCarrito }}</span></span>
						</p>
						<a href="/checkout" *ngIf="logueado != null"><button
								type="button" class="btn btn-secondary">Finalizar
								compra</button></a> <a href="/login" *ngIf="logueado == null"><button
								type="button" class="btn btn-secondary">Finalizar
								compra</button></a>
					</div>
					<button type="button" class="btn btn-primary medios"
						data-toggle="modal" data-target="#exampleModal">Medios de
						Pago</button>
					<div class="publicidad">
						<div id="carouselExampleSlidesOnly" class="carousel slide"
							data-ride="carousel">
							<div class="carousel-inner">
								<div class="carousel-item container"
									*ngFor="let oferta of ofertas; index as i"
									[ngClass]="{ 'active': i == 0 }">
									<div class="row">
										<div class="col-6 img-slide">
											<a href="/producto/{{ oferta.producto.amigable }}"> <img
												class="d-block w-100 img-fluid"
												*ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
												[src]="pathImagen(oferta.producto?.foto)" alt="{{ oferta.producto?.amigable }}"onerror="this.src='/assets/images/Logo-ER(3).png'">
												<img class="d-block w-100 img-fluid"
												*ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
												src="/assets/images/Logo-ER(3).png" alt="{{ oferta.producto?.amigable }}">
											</a>
										</div>
										<div class="col-6 slide-info">
											<div class="slide-p">
												<a href="/producto/{{ oferta.producto.amigable }}">
													<h6 style="-webkit-box-orient: vertical;">{{
														oferta.producto.nombre }}</h6>
												</a>
											</div>
											<p>
												$<span>{{ oferta.monto }}</span>
											</p>

											<div
												*ngIf="oferta.producto?.stock > 0 ; then productoConStock else productoSinStock">
											</div>

											<ng-template #productoConStock>
												<a style="cursor: pointer;"
													(click)="agregarCarritoOferta(oferta)"
													class="buy-c d-none d-lg-block"><span
													class="lnr lnr-cart"></span>Comprar</a>
											</ng-template>

											<ng-template #productoSinStock>
											  <p class="btn-agotado">AGOTADO!</p>
											</ng-template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="publicidad-custom container" *ngIf="banner != null">
					<div class="row">
						<a href="https://{{ banner?.link }}"><img class="img-fluid"
							[src]="pathImagen(banner?.pathImagen)" alt=""></a>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
	aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">En 12 cuotas con
					tarjetas</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<img class="img-fluid" src="./assets/images/tarjetas.png"
					alt="tarjetas">
				<p>Ingresas los datos de tu tarjeta de crédito una sola vez. En
					las compras siguientes solo te pediremos el código de seguridad.</p>
				<a href="https://www.mercadopago.com.ar/cuotas">Bancos que
					ofrecen cuotas sin interés</a>
				<div class="row">
					<img class="img-fluid" src="./assets/images/lnr-clock.svg"
						alt="entrega">
					<p>Acreditación instantanea.</p>
				</div>
				<div class="row">
					<img class="img-fluid" src="./assets/images/truck.svg"
						alt="entrega">
					<p>Entrega del pedido dentro de los 2 días hábiles de realizado
						el pago.</p>
				</div>

				<h5>En efectivo en Puntos de Pagos</h5>
				<img class="img-fluid" src="./assets/images/bancos.png" alt="bancos">
				<p>
					Al momento de pagar te diremos como hacerlo en cualquier sucursal
					de <b>Pago Fácil, Rápipago, Provincia Pagos</b> o en algun cajero
					automático de <b>Banelco, Red Link o Home Banking</b> adherido.
				</p>
				<div class="row">
					<img class="img-fluid" src="./assets/images/lnr-clock.svg"
						alt="entrega">
					<p>Acreditación de 1 a 3 días hábiles</p>
				</div>
				<div class="row">
					<img class="img-fluid" src="./assets/images/truck.svg"
						alt="entrega">
					<p>Entrega del pedido dentro de los 5 días hábiles de realizado
						el pago.</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>

<button #botonAlerta style="display: none;" type="button"
	data-toggle="modal" data-target="#modalAlerta"></button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog"
	aria-labelledby="modalAlertaLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">

				<button #cerrarAlerta type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">{{ mensaje }}</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

			</div>
		</div>
	</div>
</div>

<button #botonModalEliminar style="display: none;" type="button"
	data-toggle="modal" data-target="#modalEliminar"></button>

<div class="modal fade modal-mp" id="modalEliminar" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button #cerrarModalEliminar type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">Está a punto de eliminar
				este ítem de su carrito de compras.</div>
			<div class="modal-body">
				<div class="botones-pago">
					<button class="btn btn-primary btn-pago"
						(click)="cancelarEliminar()">Cancelar</button>
					<button class="btn btn-primary btn-pago"
						(click)="eliminarCarrito()">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<button #botonModalVaciar style="display: none;" type="button"
	data-toggle="modal" data-target="#modalVaciar"></button>

<div class="modal fade modal-mp" id="modalVaciar" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button #cerrarModalVaciar type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">Está a punto de vaciar su
				carrito de compras.</div>
			<div class="modal-body">
				<div class="botones-pago">
					<button class="btn btn-primary btn-pago" (click)="cancelarVaciar()">Cancelar</button>
					<button class="btn btn-primary btn-pago" (click)="vaciarCarrito()">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>
