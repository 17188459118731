import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BannerServicio = /** @class */ (function () {
    function BannerServicio(http) {
        this.http = http;
        this.server = environment.baseUrl + '/api/banner';
    }
    BannerServicio.prototype.obtenerBanner = function () {
        return this.http.get(this.server);
    };
    BannerServicio.ngInjectableDef = i0.defineInjectable({ factory: function BannerServicio_Factory() { return new BannerServicio(i0.inject(i1.HttpClient)); }, token: BannerServicio, providedIn: "root" });
    return BannerServicio;
}());
export { BannerServicio };
