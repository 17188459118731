import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Cliente } from '../entidades/cliente';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Categoria } from '../entidades/categoria';
import { Producto } from '../entidades/producto';
import { Page } from '../entidades/page';
import { Oferta } from '../entidades/oferta';

@Injectable({
    providedIn: 'root'
})
export class ProductoServicio {

    public server: string = environment.baseUrl + '/api/producto';

    constructor(private http: HttpClient) {}

    listarCategorias(): Observable<Array<Categoria>> {
        return this.http.get<Array<Categoria>>(this.server + '/categorias');
    }

    listarOfertas(): Observable<Array<Oferta>> {
        return this.http.get<Array<Oferta>>(this.server + '/ofertas');
    }

    listarNovedades(): Observable<Array<Producto>> {
        return this.http.get<Array<Producto>>(this.server + '/novedades');
    }

    listarMasComprados(): Observable<Array<Producto>> {
        return this.http.get<Array<Producto>>(this.server + '/mas-comprados');
    }

    pageProductosPorCategoria(idCategoria, pagina): Observable<Page<Producto>> {
        let p = {};
        p['size'] = 16;
        p['page'] = pagina;
        return this.http.get<Page<Producto>>(this.server + '/categorias/' + idCategoria, { params: p });
    }

    pageProductosPorNombre(nombre, pagina): Observable<Page<Producto>> {
        let p = {};
        p['size'] = 16;
        p['page'] = pagina;
        p['nombre'] = nombre;
        return this.http.get<Page<Producto>>(this.server + '/buscar', { params: p });
    }

    buscarPorAmigable(amigable): Observable<Producto> {
        return this.http.get<Producto>(this.server + '/' + amigable);
    }

    buscarPorNombre(nombre): Observable<Array<Producto>> {
        let p = {
            nombre: nombre
        }
        return this.http.get<Array<Producto>>(this.server, { params: p });
    }

    public getImagen(foto: string) {
        return this.server + '/abrir?foto=' + foto;
    }

    incrementarVistas(productoId: string): Observable<Response> {
        return this.http.post<Response>(this.server + '/visitas/' + productoId, null);
    }

}
