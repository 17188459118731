import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/entidades/cliente';
import { VentaServicio } from 'src/app/servicios/venta.servicio';
import { Venta } from 'src/app/entidades/venta';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  logueado: Cliente;

  @ViewChild('inputDesde') inputDesde: ElementRef;
  @ViewChild('inputHasta') inputHasta: ElementRef;

  @ViewChild('botonAlerta') botonAlerta: ElementRef;

  mensaje: string;

  ventas: Venta[] = [];

  constructor(private router: Router, private ventaServicio: VentaServicio) { }

  ngOnInit() {
    this.obtenerLogueado();
    this.obtenerVentas();
  }

  obtenerLogueado() {
    this.logueado = JSON.parse(localStorage.getItem('logueado'));
    if (this.logueado == null) {
      this.router.navigate(['/']);
    }
  }

  obtenerVentas() {
    this.ventaServicio.ventasPorCliente(this.logueado.id, this.inputDesde.nativeElement.value, this.inputHasta.nativeElement.value).subscribe(res => {
      this.ventas = res;
      this.ventas.forEach(v => {
        v.monto = v.envio + v.monto;
        v.monto = this.ordenarDecimales(v.monto);
      })
    }, error => {
      this.mensaje = "Hubo un error al obtener las ventas por cliente. Intente nuevamente más tarde.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    })
  }

  ordenarDecimales(valor) {
    let numero;
    if (Number(valor) % 1 != 0) {
      let decimales: string = valor.toString().substring(valor.toString().indexOf('.') + 1);
      if (decimales.length == 1) {
        decimales = decimales + '0';
      } else if (decimales.length >= 2) {
        decimales = decimales.substring(0, 2);
      }
      numero = valor.toString().substring(0, valor.toString().indexOf('.')) + '.' + decimales;
    } else {
      numero = valor + '.00';
    }
    return numero;
  }

  elegirVenta(venta) {
    localStorage.setItem('venta', JSON.stringify(venta));
  }

  buscar() {
    if (this.inputDesde.nativeElement.value != '' && this.inputHasta.nativeElement.value != '') {
      this.obtenerVentas();
    } else {
      this.mensaje = "Para buscar debe ingresar fecha 'desde' y 'hasta'.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    }
  }

}
