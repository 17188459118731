import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/entidades/cliente';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  logueado: Cliente = null;
  
  constructor() { }

  ngOnInit() {
    if (localStorage.getItem('logueado') != null && localStorage.getItem('logueado') != '') {
      this.logueado = JSON.parse(localStorage.getItem('logueado'));
    }
  }

}
