import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DescuentoServicio = /** @class */ (function () {
    function DescuentoServicio(http) {
        this.http = http;
        this.server = environment.baseUrl + '/api/descuento';
    }
    DescuentoServicio.prototype.getDescuento = function (codigo, idCliente) {
        var p = {
            codigo: codigo,
            idCliente: idCliente
        };
        return this.http.get(this.server, { params: p });
    };
    DescuentoServicio.ngInjectableDef = i0.defineInjectable({ factory: function DescuentoServicio_Factory() { return new DescuentoServicio(i0.inject(i1.HttpClient)); }, token: DescuentoServicio, providedIn: "root" });
    return DescuentoServicio;
}());
export { DescuentoServicio };
