<app-header></app-header>

<section class="register">
  <div class="container">
      <h5>POLITICAS DE PRIVACIDAD</h5>
      <div class="colorbar"><span></span></div>
  </div>
</section>

<section class="privacy">
  <div class="container">
      <h6>Apertura de cuenta</h6>
      <p>Para realizar la apertura de cuenta sólo es necesario realizar un pedido y Registrarse en el sitio.. En caso de ser necesario, se te solicitará la siguiente documentación:</p>
      <p>Constancia de inscripción AFIP (Responsable Inscripto / Monotributista)</p>
      <p>Comprobante de Exención de IIBB, si corresponde.</p>
      <p>Todos los pedidos hábiles antes de las 17hs. se procesarán en el día y se entregarán de acuerdo a las Condiciones de entrega abajo detalladas.</p>
      
      <h6>Reclamos, Cambios y devoluciones</h6>
      <p>A partir del momento en que recibís tu pedido, hay 3 días corridos para realizar el reclamo correspondiente. Dicho plazo aplica tanto en compras realizadas en www.papeleriaentrerios.com como en nuestras tiendas. Una vez aceptada la devolución de un producto se generará inmediatamente un crédito en la cuenta corriente del cliente. Dicho crédito quedará a cuenta de futuros consumos (sin fecha de caducidad), o podrá solicitarse el rembolso. En este caso, el rembolso se realizará en el mismo medio de pago en el que fuera abonado el pedido de origen.</p>
      <p>Para devoluciones o cambios de productos adquiridos en nuestras tiendas deberán acercarse a la tienda en la que fue comprado el producto dentro del plazo de 15 días con el ticket de compra.</p>
      <p>Para devoluciones o cambios de productos adquiridos en wwww.papeleriaentrerios.com deberán hacer su reclamo a nuestro Servicio de Atención al Cliente info@papeleriaentrerios.com</p>
      <p>Condiciones y estado de los productos a devolver:</p>
      <p>Los reclamos sobre productos de Hewlett Packard deberán ser realizados en el Centro de Asistencia Técnica HP 0800-555-5000</p>
      <p>Todos los productos deberán ser devueltos en las mismas condiciones en que fueron recibidos.</p>
      <p>Los productos devueltos deberán entregarse junto a los embalajes originales y en perfecto estado de conservación, con los accesorios, manuales e instructivos del mismo. En el caso de productos recibidos por error, los embalajes deberán estar intactos, sin abrir.</p>
      <p>Recomendamos controlar que la cantidad de bultos/cajas recibida coincida con lo informado en la factura.</p>
      <h6>Plazos Condiciones de Entrega</h6>
      <h6>Gran Mendoza</h6>
      <p>Plazo de entrega 24hs hábiles.</p>
      <p>Las entregas se realizan en días hábiles de 9 a 20.30hs.</p>
      <p>Si el pedido es realizado después de las 17hs se considerará como un pedido del día siguiente y consecuentemente tendrá un día más de plazo de entrega.</p>
      <h6>Interior del país:</h6>
      <p>Plazo de entrega de entre 48 y 96hs en Capitales y Ciudades Cabecera. En otras ciudades, el plazo variará dependiendo del destino.</p>
      <p>Pedidos sin monto mínimo.</p>
      <p>Los pedidos fuera del ámbito de GBA tendrán un cargo por envío. El mismo es variable dependiendo del destino y del contenido del pedido, y se informará durante el proceso de confirmación del pedido, antes de la confirmación final del mismo.</p>
      <p>Si el pedido es realizado después de las 17hs se considerará como un pedido del día siguiente y consecuentemente tendrá un día más de plazo de entrega.</p>
      <h6>Precios</h6>
      <p>KADIMA S.A.se reserva el derecho de modificar, sin previo aviso, los precios mencionados en el presente sitio o en cualquiera de sus publicaciones gráficas o mensajes por e-mail. Los precios publicados en todas las piezas gráficas y en el sitio de internet wwww.papeleriaentrerios.com  incluyen IVA, exceptuando usuarios registrados como Responsables inscriptos o aquellos a quienes expresamente se les informe que sus precios NO incluyen IVA.</p>
      <p>La utilización de este sitio Web y de cualquiera de los servicios del mismo significa su aceptación como usuario, sin reservas de ninguna clase, de todas y cada una de las presentes condiciones generales.</p>
      <h6>Servicios prestados por medio del Sitio Web</h6>
      <p>El Sitio Web ofrecerá los siguientes servicios:</p>
      <p>Información general y posibilidad de adquisición de los productos y servicios ofrecidos por Kadima S.A.</p>
      <p>Ofertas y promociones ofrecidas por Kadima S.A.</p>
      <p>Medios de contacto con Kadima S.A.</p>
      <p>Kadima S.A.se reserva el derecho de modificar unilateralmente la presentación y configuración del Sitio Web, así como los correspondientes servicios y condiciones requeridas para su uso, sin que de ello se derive derecho alguno de compensación a favor del usuario.</p>
      <h6>Propiedad Intelectual e Industrial</h6>
      <p>El usuario reconoce que todos los elementos del Sitio Web, de cada uno de sus servicios prestados, la información, estructura, selección, ordenación y presentación de sus contenidos, y los programas de ordenador utilizados en relación con el mismo están protegidos por derechos de propiedad intelectual e industrial de la propia Kadima S.A.o de terceros. Salvo que fuera autorizado por Kadima S.A.o, en su caso, por los terceros titulares de los derechos correspondientes, o a menos que ello resulte legalmente permitido, el usuario no podrá reproducir, transformar, modificar, desensamblar, realizar ingeniería inversa, distribuir, alquilar, prestar, poner a disposición o permitir el acceso al público a través de cualquier modalidad de comunicación pública de ninguno de los elementos referidos en el párrafo anterior.</p>
      <p>El usuario no tendrá permitido explotar comercialmente (ni directa ni indirectamente) los materiales, servicios e información a los que acceda a través de la utilización del Sitio Web y de cada uno de los correspondientes servicios.</p>
      <h6>Exclusión de Garantías</h6>
      <p>Kadima S.A.se compromete a llevar a cabo todos los esfuerzos necesarios para garantizar la disponibilidad y continuidad del Sitio Web así como de los servicios vinculados al mismo, pero no garantizará que los mismos funcionen correctamente en todo momento, que el usuario pueda acceder a ellos y utilizarlos de forma rápida, ininterrumpida y libre de errores. De la misma forma, Kadima S.A.no otorga ninguna garantía respecto a la idoneidad y contenido del Sitio Web o de cualquiera de los servicios vinculados al mismo para la satisfacción de las necesidades específicas del usuario. Respecto al contenido del Sitio Web, el mismo recoge únicamente características e información general que pueden variar en el tiempo.</p>
      <h6>Protección de datos personales</h6>
      <p>A los efectos de lo previsto en la Ley 25.326, de Protección de Datos Personales (Habeas Data) y sus reglamentaciones vigentes, Kadima S.A.informa al usuario de la existencia de un fichero automatizado de datos de carácter personal creado por y para Kadima S.A.y bajo su responsabilidad, con la finalidad de realizar el mantenimiento y gestión de la relación con el usuario, así como las labores de información, formación y comercialización de sus productos y servicios y de actividades relacionadas con los mismos. En el momento de la solicitud de alguno de los servicios vinculados al Sitio Web, Kadima S.A.precisará recolectar datos del usuario para la prestación de los mencionados servicios, informando al usuario del carácter necesario o facultativo de la recolección de tales datos para la prestación del correspondiente servicio. No obstante, la falta de cumplimentación de los datos facultativos impedirá a Kadima S.A.prestar todos aquellos otros servicios vinculados a tales datos.</p>
      <p>Kadima S.A.al usuario sobre la posibilidad que le asiste de ejercitar los derechos de acceso, rectificación, oposición y cancelación de los datos personales recogidos por Kadima S.A. Dichos derechos podrán ser ejercitados por el usuario, y en su caso quien lo represente, mediante solicitud escrita y firmada dirigida a Kadima S.A.. (Entre Ríos 72, Ciudad de Mendoza) conteniendo los siguientes datos: nombre y apellido del usuario, domicilio a efectos de notificaciones, fotocopia del DNI o Pasaporte, y petición en que se concreta la solicitud. En el caso de representación, deberá probarse la misma mediante documento fehaciente.</p>
      <h6>Comunicaciones comerciales</h6>
      <p>El usuario consiente que, en caso de que comunique a Kadima S.A.su dirección postal o de correo electrónico,Kadima S.A.pueda dirigirle comunicaciones comerciales referidas a sus productos y/o servicios por dichos medios, pudiendo solicitar en cualquier momento el cese en el envío de dichas comunicaciones. Para ello, el usuario podrá dirigirse a Kadima S.A.por medio de correo electrónico al info@papeleriaentrerios.com  o por correo postal a la dirección de Kadima S.A.</p>
      <h6>Nota de confidencialidad para e-mails enviados por Kadima SA</h6>
      <p>Los mensajes (y sus archivos adjuntos) enviados por cualquier asociado de Kadima S.A.son para uso exclusivo del destinatario y pueden contener información confidencial o propietaria, cuya divulgación es sancionada por ley. Si usted recibió un mensaje erróneamente, por favor notifíquenos respondiendo al remitente, borre el mensaje original y destruya las copias (impresas o grabadas en cualquier medio magnético) que pueda haber realizado del mismo. Todas las opiniones contenidas en los e-mails son propias del autor del mensaje y no necesariamente coinciden con las de Kadima S.A.. o alguna de las empresas accionistas. La publicación, uso, copia e impresión total o parcial de dichos mensajes o documentos adjuntos queda prohibida.</p>
      <p>Muchas gracias</p>

  </div>

</section>

<app-footer></app-footer>