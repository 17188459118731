<app-header></app-header>

<section class="forms">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-6  d-none d-md-block">
        <div class="gray">
          <h5>INGRESAR</h5>
          <div class="colorbar"><span></span></div>
          <form [formGroup]="loginForm" (ngSubmit)="loginCliente()">
            <div class="form-group">
              <input formControlName="usuario" type="text" class="form-control" placeholder="Ingrese su nombre de usuario">
            </div>
            <div class="form-group">
              <input formControlName="clave" type="password" class="form-control" placeholder="Ingrese su contraseña">
            </div>
            <button type="submit" class="btn btn-primary">Ingresar a su cuenta</button>
          </form>

          <a href="/restore-pass">¿Olvidó sus datos?</a>
        </div>
      </div>

      <div class="col-12 col-md-6 d-none d-md-block">
        <div class="gray n-user">
          <h5>¿NUEVO USUARIO?</h5>
          <div class="colorbar"><span></span></div>
          <form>
            <div class="form-group">
              <input #inputEmail type="email" class="form-control" placeholder="Ingrese su nombre de email">
            </div>
            <button type="submit" class="btn btn-primary" (click)="registrarse()">Registrar nueva cuenta</button>
          </form>
          <a href="https://api.whatsapp.com/send?phone=2616607476" class="contact-link">
            Si necesita ayuda, contáctenos
          </a>
        </div>
      </div>

      <div class="col-12 col-md-6  d-block d-md-none" *ngIf="login">
        <div class="gray">
          <h5>INGRESAR</h5>
          <div class="colorbar"><span></span></div>
          <form [formGroup]="loginForm" (ngSubmit)="loginCliente()">
            <div class="form-group">
              <input formControlName="usuario" type="text" class="form-control" placeholder="Ingrese su nombre de usuario">
            </div>
            <div class="form-group">
              <input formControlName="clave" type="password" class="form-control" placeholder="Ingrese su contraseña">
            </div>
            <button type="submit" class="btn btn-primary">Ingresar a su cuenta</button>
          </form>

          <a href="/restore-pass">¿Olvidó sus datos?</a>
          <div class="row">
            <a class="d-block d-md-none" style="cursor: pointer;" (click)="irRegistro()">Registrar nueva cuenta</a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 d-block d-md-none" *ngIf="!login">
        <div class="gray n-user">
          <h5>¿NUEVO USUARIO?</h5>
          <div class="colorbar"><span></span></div>
          <form>
            <div class="form-group">
              <input #inputEmailM type="email" class="form-control" placeholder="Ingrese su cuenta de email"
                aria-describedby="emailHelp">
            </div>
            <button type="submit" class="btn btn-primary" (click)="registrarseM()">Registrar nueva cuenta</button>
          </form>
          <p>Si necesita ayuda, contáctenos</p>
        </div>
      </div>

    </div>
  </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
