<app-header></app-header>

<section class="register">
  <div class="container">
      <h5>PREGUNTAS FRECUENTES</h5>
      <div class="colorbar"><span></span></div>
      <p>El siguiente listado contiene las respuestas a las preguntas realizadas con frecuencia. Si la pregunta que tienes no se encuentra respondida aquí puedes leer los términos y condiciones o puedes consultarnos enviándonos un correo a info@papeleriaentrerios.com</p>
  </div>
</section>

<section class="faqs">
  <div class="container">
      <div class="row">
          <a data-toggle="collapse" href="#faq1" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Cómo se realiza el primer pedido?
          </a>
          <div class="collapse" id="faq1">
              <div class="card card-body">
                  <p>Para comprar en Papelería Entre Ríos deberás ingresar tu usuario y contraseña haciendo <a href="/login">click aquí</a>. Si aún NO sos cliente, deberás completar el formulario para Nuevos Clientes.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq2" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Cómo empiezo a comprar?
          </a>
          <div class="collapse" id="faq2">
              <div class="card card-body">
              <p>Para comenzar a comprar podés buscar los productos desde nuestro buscador o ingresar <a href="/"> aquí </a>para ver los productos más comprados</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq3" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Cómo modificar los datos?
          </a>
          <div class="collapse" id="faq3">
              <div class="card card-body">
                <p *ngIf="logueado != null">Habiendo ingresado tu usuario y contraseña, podés modificar tus datos desde el menú Mi Cuenta, haciendo click en la opción <a href="/change-info">Mis Datos.</a></p>
                <p *ngIf="logueado == null">Habiendo ingresado tu usuario y contraseña, podés modificar tus datos desde el menú Mi Cuenta, haciendo click en la opción <a href="/login">Mis Datos.</a></p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq4" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Olvidaste tu contraseña?
          </a>
          <div class="collapse" id="faq4">
              <div class="card card-body">
              <p>Hacé <a href="/restore-pass">click aquí</a> para solicitar la recuparación de tu contraseña.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq5" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Cómo confirmar un pedido?
          </a>
          <div class="collapse" id="faq5">
              <div class="card card-body">
              <p>Para confirmar tu pedido deberás haber terminado de realizar tu compra y hacer clic en el botón Finalizar Compra</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq6" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Cómo saber si tu orden fue recibida correctamente por Papeleria Entre Rios?
          </a>
          <div class="collapse" id="faq6">
              <div class="card card-body">
              <p>Al concluir el proceso de compra, recibirás un email con la confirmación y número del pedido.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq7" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Puedo cancelar un pedido?
          </a>
          <div class="collapse" id="faq7">
              <div class="card card-body">
              <p>Si, reenviá a tu Ejecutivo de Cuenta el e-mail que recibiste con la confirmación del pedido indicando que deseás cancelarlo, o bien comunicate con él telefónicamente, antes de las 17 hs. del día hábil en el que realizaste el pedido, o si la confirmación del pedido hubiera sido realizada después de las 17 hs. o en día no hábil deberás comunicarte antes de las 17 hs. del día hábil inmediato posterior a dicha confirmación.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq8" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Puedo solicitar un producto que quiero y no está disponible en stock?
          </a>
          <div class="collapse" id="faq8">
              <div class="card card-body">
              <p>Si, podés comprar todo aquello que se encuentre en stock y antes de confirmar tu compra agregar un mensaje para tu Ejecutivo de Cuentas en el campo Observaciones indicándole el producto que querías adquirir y que se encontraba sin stock</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq9" role="button" aria-expanded="false" aria-controls="collapseExample">
             ¿Puedo cambiar cantidades y eliminar productos una vez seleccionados?

          </a>
          <div class="collapse" id="faq9">
              <div class="card card-body">
              <p>Sí, ingresá al Carrito y modificá las cantidades que desees, luego presioná el ícono comprar para actualizar los totales. Si deseás eliminar un producto, presiona el ícono (x) que se encuentra en el carrito.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq10" role="button" aria-expanded="false" aria-controls="collapseExample">
          ¿Puedo llevar el control de mis compras?

          </a>
          <div class="collapse" id="faq10">
              <div class="card card-body">
                <p *ngIf="logueado != null">Si, habiendo ingresado tu usuario y contraseña, podrás ver en la sección dentro de Mi Cuenta las compras realizadas. Allí podrás y ver el <a href="/purchase"> historial de compras </a>en detalle.</p>
                <p *ngIf="logueado == null">Si, habiendo ingresado tu usuario y contraseña, podrás ver en la sección dentro de Mi Cuenta las compras realizadas. Allí podrás y ver el <a href="/login"> historial de compras </a>en detalle.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq11" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Los pedidos se entregan al día siguiente de ser realizados?

          </a>
          <div class="collapse" id="faq11">
              <div class="card card-body">
              <p>Sí, los pedidos confirmados antes de las 17 hs. del día se entregarán al día siguiente hábil en el horario de 9 a 18 hs. para los pedidos de clientes con dirección de entrega en Ciudad de Mendoza</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq12" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Papeleria Entre Rios tiene locales de venta al público?

          </a>
          <div class="collapse" id="faq12">
              <div class="card card-body">
              <p>Papeleria Entre Rios ofrece los productos de su catálogo para ser adquiridos telefónicamente o a través de su sitio web. Si deseás comprar en los locales, podés hacerlo en las sucursales de  Papeleria Entre Rios y allí encontrarás miles de productos para la oficina y más.</p>
              <p>Sucursales Papeleria Entre Rios:</p>   
              <p>• Entre Rios 72, Ciudad, Mendoza</p>
              <p>• San Martín 1126 Ciudad, Mendoza</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq13" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Puedo realizar un reclamo o consulta?
          </a>
          <div class="collapse" id="faq13">
              <div class="card card-body">
              <p>Si, para temas urgentes podés enviar un e-mail a info@papeleriaentrerios.com, o bien llamar al (0261) 4413550.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq14" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Con quién debo comunicarme si tengo problemas con la página?
          </a>
          <div class="collapse" id="faq14">
              <div class="card card-body">
              <p>Podés llamar al (0261) 4413550 o bien enviar un e-mail a <a href="/support"> Soporte Técnico.</a> Es recomendable que detalles lo que estabas tratando de hacer para brindarnos mayor información que nos ayude a poder brindarte la mejor solución posible.</p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq15" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Cuentan con una política de seguridad de los datos e información personal?
          </a>
          <div class="collapse" id="faq15">
              <div class="card card-body">
              <p>Sí, Papeleria Entre Rios es un sitio seguro, que garantiza el manejo seguro de la información personal. Más información en<a href="/privacy"> Seguridad y Privacidad.</a></p>
              </div>
          </div>
      </div>
      <div class="row">
          <a data-toggle="collapse" href="#faq16" role="button" aria-expanded="false" aria-controls="collapseExample">
              ¿Preguntas sin contestar?
          </a>
          <div class="collapse" id="faq16">
              <div class="card card-body">
              <p>Si no encontraste una respuesta a tu inquietud, consultá <a href="/privacy">Términos y Condiciones </a> y si aún no la encontrás, no dudes en escribirnos a info@papeleriaentrerios.com responderemos tu consulta a la brevedad.</p>
              </div>
          </div>
      </div>
  </div>

</section>

<app-footer></app-footer>