import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Cliente } from '../entidades/cliente';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ClienteServicio {

    public server: string = environment.baseUrl + '/api/cliente';

    constructor(private http: HttpClient) {}

    registro(cliente: Cliente): Observable<any> {
        return this.http.post<any>(this.server, cliente);
    }

    modificar(cliente: Cliente): Observable<string> {
        return this.http.post<string>(this.server + '/modificar', cliente);
    }

    enviarSupport(nombre, mail, mensaje): Observable<string> {
        let p = {
            nombre: nombre,
            mail: mail,
            mensaje: mensaje
        }
        return this.http.post<string>(this.server + '/support', null, { params: p });
    }

    agregarDireccion(direccion: string, idCliente: string) {
        let p = {
            direccion: direccion
        }
        return this.http.post<string>(this.server + '/direccion/' + idCliente, null, { params: p });
    }

    login(usuario: string, clave: string): Observable<Cliente> {
        let p = {
            usuario: usuario,
            clave: clave
        }
        return this.http.post<Cliente>(this.server + '/login', null, { params: p });
    }

    modificarClave(email: string, actual: string, nueva: string): Observable<any> {
        let p = {
            email: email,
            actual: actual,
            nueva: nueva
        }
        return this.http.post<any>(this.server + '/modificar-clave', null, { params: p });
    }

    buscarPorEmail(email: string): Observable<Cliente> {
        let p = {
            email: email
        }
        return this.http.get<Cliente>(this.server + '/email', { params: p });
    }

    buscarDireccion(idCliente: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(this.server + '/direccion/' + idCliente);
    }

    validarCuenta(token: string): Observable<any> {
        let p = {
            token: token
        }
        return this.http.post<any>(this.server + '/validar', null, { params: p });
    }

    restablecerClave(email: string): Observable<string> {
        let p = {
            email: email
        }
        return this.http.post<string>(this.server + '/restablecer-clave', null, { params: p });
    }

    recuperarCuenta(token: string): Observable<string> {
        let p = {
            token: token
        }
        return this.http.post<string>(this.server + '/recuperar-cuenta', null, { params: p });
    }

}